import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom"; // Add this import
import {
  createSCORMFiles,
  createPreview,
  updateSlide,
} from "../../utils/helpers";
import { useAuth } from "../../context/AuthContext";
import ScormHelper from "../../utils/scormHelper";
import { useAlert } from "../../context/AlertContext";
import {ArrowLeft, UTurnArrowLeft, Eye, DownloadCircle, FloppyDisk} from "iconoir-react"

const Header = () => {
  const [uploadStatus, setUploadStatus] = useState(false);
  const { slideList, activeSlide } = useSelector((state) => state.slideList);
  const navigate = useNavigate();
  const { user} = useAuth();
  const { showAlert } = useAlert();
  const location = useLocation(); // Add this line
  const { projectName } = location.state || {};


  const handleJsonUpload = () => {
    const scormHelper = new ScormHelper(slideList);
    const jsonData = scormHelper.getStringifyJSON();
    const currentSlideJson = jsonData.find((i) => i.id === activeSlide.id);
    if (!currentSlideJson) {
      console.error("No JSON data provided.");
      return;
    }
    
    setUploadStatus(true);
    const body = {
      data: {
        objects: JSON.stringify(currentSlideJson.media),
        name: currentSlideJson.name,
        thumbnail: currentSlideJson.thumbnail || '',
      },
    };  
    
    updateSlide(currentSlideJson?.id, body, () => {
      setUploadStatus(false);
    }, showAlert);
  };

  const handleNavigate = (path) => {
    navigate(path);
  };
  

  return (
    <header className="h-16 flex items-center justify-between bg-white py-3 shadow-md">
      <div className="flex items-center space-x-3"> {/* Add this wrapper div */}
        <div className="border-b-[1px] border-white w-[85px] flex flex-col items-center text-xs py-3 cursor-pointer"
          onClick={() => handleNavigate("/dashboard")}
        >
          <ArrowLeft className="w-6 h-6 text-custom-topBlue" />
          <span className="text-custom-topBlue font-xxs">Dashboard</span>
        </div>
        <p className="text-grey">{projectName}</p> {/* Move this inside the new wrapper div */}
        <div className="flex align-left items-center space-x-3 border-r-[1px] border-l-[1px] border-grey">
          <UTurnArrowLeft className="w-6 h-6 text-grey rotate-90" />
          <UTurnArrowLeft className="w-6 h-6 text-grey -rotate-90" /> {/* Apply mirror rotation */}
        </div>
      </div>
      <div className="flex items-center space-x-3">
        {location.pathname !== "/dashboard" && location.pathname !== "/profile" && !location.pathname.startsWith("/edit-project/") && ( // Add this condition
          <>
            
            <button
              className="flex items-center rounded-md border-[1px] border-grey px-[15px] py-[6px] text-xs text-grey hover:bg-grey-200"
              onClick={() => createSCORMFiles(slideList)}
            >
              <DownloadCircle className="w-4 h-4 mr-2 text-grey" />
              Download
            </button>
            <button
              className="flex items-center rounded-md border-[1px] border-grey px-[15px] py-[6px] text-xs text-grey hover:bg-grey-200"
              onClick={() => createPreview(slideList)}
            >
              <Eye className="w-4 h-4 mr-2 text-grey" />
              Preview
            </button>
            <button
              className="flex items-center rounded-md bg-custom-lightBlue px-[15px] py-[6px] text-xs text-white hover:bg-grey-200"
              onClick={() => handleJsonUpload()}
              disabled={uploadStatus}
            >
              <FloppyDisk className="w-4 h-4 mr-2 text-grey" />
              {uploadStatus ? "Uploading..." : "Save"}
            </button>
          </>
        )}
        <div className="relative">
          <div className="cursor-pointer px-4"
          onClick={() => handleNavigate("/profile")}>
          <img
            src={user?.avatar ? `https://backend.ailms.co${user?.avatar}` : <div className="w-8 h-8 rounded-full bg-gray-300"></div>}
            alt="Profile"
            className="w-8 h-8 rounded-full border-[1px] border-grey"
          />
          </div>
          
        </div>
      </div>
    </header>
  );
};

export default Header;