import React, { useState, useEffect } from "react";
import Topbar from "../../modules/LayoutModule/Content/Topbar/Topbar";
import { useNavigate } from 'react-router-dom'; 
import TextSearch from '../../components/TextSearch/TextSearch'; // Add this import
import Footer from "../../modules/LayoutModule/Content/Footer/Footer";
import { publicApi } from "../../api/publicApi";
import { MoneySquare, ThumbsUp, Eye } from "iconoir-react";

const Marketplace = () => {
  const navigate = useNavigate();
  const [sortOption, setSortOption] = useState("Recommended");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const [searchResults, setSearchResults] = useState([]); // Add this state
  

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await publicApi.get(`/api/projects?filters[status][$eq]=public`);
        if (response.data) {
          setProjects(response.data); // Set local state
          setSearchResults(response.data); // Local state for search results
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []); 

  const handleOnSearch = (string, results) => {
    const filteredProjects = projects.filter(project =>
      project.name.toLowerCase().includes(string.toLowerCase())
    );
    setSearchResults(filteredProjects);
  };

  const handleOnSelect = (item) => {
    navigate(`/project/${item.id}/${item.name}`);
  };

  return (
    <div>
      <Topbar /> {/* Add Topbar component */}
       {/* Jumbotron with Search Bar */}
       <div className="relative text-white text-center w-full"> {/* Removed py-12 */}
          <img src="header_image.png" alt="Header" className="absolute inset-0 w-full h-full object-cover" /> {/* Full width image */}
          <img src="component4.png" alt="Component 4" className="absolute bottom-10 left-0 w-24 h-4" /> 
          <img src="component4.png" alt="Component 4" className="absolute top-10 opacity-50 right-0 w-24 h-4" /> {/* Added image in the bottom left corner */}
          <div className="relative z-10 py-12"> {/* Added py-12 to inner div */}
            <h1 className="text-4xl font-bold mb-4">Marketplace</h1>
            <p className="mb-6">Explore our wide range of projects and assets available for purchase.</p>
            <div className="max-w-md mx-auto relative mb-6"> {/* Changed max-w-lg to max-w-md */}
            <TextSearch
              items={projects.map(project => ({ id: project.project_id, name: project.name }))}
              onSearch={handleOnSearch}
              onSelect={handleOnSelect}
              placeholder="Search for templates"
            />
            </div>
          </div>
        </div>
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/4 p-4">
          <h2 className="text-xl font-bold mb-4">Filters</h2>
          <div className="mb-4">
            <h3 className="font-semibold">Projects</h3>
            {/* Add filter options */}
          </div>
          <div className="mb-4">
            <h3 className="font-semibold">Assets</h3>
            {/* Add filter options */}
          </div>
          <div className="mb-4">
            <h3 className="font-semibold">People</h3>
            {/* Add filter options */}
          </div>
          <div className="mb-4">
            <h3 className="font-semibold">Tags</h3>
            {/* Add filter options */}
          </div>
          <div className="mb-4">
            <h3 className="font-semibold">Sector</h3>
            {/* Add filter options */}
          </div>
          <div className="mb-4">
            <h3 className="font-semibold">Price Range</h3>
            {/* Add filter options */}
          </div>
          <div className="mb-4">
            <h3 className="font-semibold">Location</h3>
            {/* Add filter options */}
          </div>
        </div>
        <div className="w-full md:w-3/4 p-4">
          <div className="flex flex-col md:flex-row items-center mb-4">
            
            <div className="relative ml-0 md:ml-4 mt-4 md:mt-0">
              <button
                className="bg-gray-200 p-2 rounded h-full w-32"
                onClick={() => setDropdownOpen(!dropdownOpen)}
                style={{ minWidth: '150px' }}
              >
                {sortOption}
              </button>
              {dropdownOpen && (
                <div className="absolute mt-2 w-48 bg-white border rounded shadow-lg left-0">
                  <ul>
                    {["Recommended", "Most Followed", "Curated", "Most Appreciated", "Most Viewed", "Most Recent"].map(option => (
                      <li
                        key={option}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          setSortOption(option);
                          setDropdownOpen(false);
                        }}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <h2 className="text-xl font-bold mb-4">Marketplace</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {searchResults.map(project => (
              <div key={project.id} className="w-full max-w-xs mx-auto bg-white rounded-lg shadow-lg p-6">
                <img 
                  src={`https://backend.ailms.co${project.project_image.formats.large?.url || project.project_image.url}`} 
                  alt={project.name} 
                  className="mb-2 w-full h-48 object-cover rounded-lg" 
                />
                
                <div className="flex justify-left mt-4 text-xxs">
                  <div className="flex items-left bg-[#e5fae4] py-1 px-2 rounded-lg">
                    <MoneySquare className="h-4 w-4 mr-1 text-gray-500" />
                    <div className="text-xxs font-bold mr-1 text-gray-500">{project.purchases || "XX"}</div>
                    <div className="text-xxs text-gray-500">Purchases</div>
                  </div>
                  {/* <div className="flex items-center bg-gray-200 hover:bg-gray-300 py-1 px-2 rounded-lg">
                    <Eye className="h-4 w-4 mr-1 text-gray-500" />
                    <div className="text-xxs font-bold mr-1 text-gray-500">{project.views || "XX"}</div>
                    <div className="text-xxs text-gray-500">Views</div>
                  </div>
                  <div className="flex items-center bg-gray-200 hover:bg-gray-300 py-1 px-2 rounded-lg">
                    <ThumbsUp className="h-4 w-4 mr-1 text-gray-500" />
                    <div className="text-xxs font-bold mr-1 text-gray-500">{project.likes || "XX"}</div>
                    <div className="text-xxs text-gray-500">Likes</div>
                  </div> */}
                </div>
                <div className="text-left mt-4">
                  <div className="text-xl font-bold text-gray-900 mb-2">{project.name}</div>
                  <div className="text-gray-500 text-sm">Author | {project.user[0]?.username || "Unknown User"}</div>
                </div>
                <div className="mt-4">
                  <div className="text-sm text-gray-500">{project.category || "N/A"}</div>
                  <div className="flex flex-wrap gap-2 mt-2">
                    {project.tags && project.tags.length > 0 ? project.tags.map(tag => (
                      <span key={tag.id} className="bg-gray-200 hover:bg-gray-300 py-1 px-2 rounded-lg text-xxs">
                        {tag.tagName}
                      </span>
                    )) : "N/A"}
                  </div>
                </div>
                <div className="mt-6 flex justify-between items-end">
                <button className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xxs font-medium text-gray-900 rounded-lg bg-custom-lightBlue hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300" style={{ minWidth: '100px' }}>
                    <span className="relative text-white px-6 text-xs font-bold py-1.5 transition-all ease-in duration-75 rounded-md group-hover:bg-opacity-0">
                      Buy ${project.price || "XX"} 
                    </span>
                  </button>
                  <button className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xxs font-medium text-gray-900 rounded-lg group bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-300">
                    <span className="relative px-4 py-1.5 transition-all ease-in duration-75 rounded-md group-hover:bg-opacity-0">
                      <ThumbsUp className="text-xxs" />
                    </span>
                  </button>
                  <button
                    className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xxs font-medium text-gray-900 rounded-lg group bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-300"
                    onClick={() => navigate(`/project/${project.project_id}/${project.name}`)}
                  >
                    <span className="relative px-4 py-1.5 transition-all ease-in duration-75 rounded-md group-hover:bg-opacity-0">
                      <Eye className="text-xxs" />
                    </span>
                  </button>
                  
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Marketplace;