import React, {useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import LayerAndLinkControl from "../TextPanel/LayerAndLinkControl";
import {v4 as uuidv4} from 'uuid';
import {
    centerFabricObject,
    getFabricImageFromURL,
    handleLoader,
    S3_BUCKET_NAME,
    uploadToS3
} from "../../../utils/helpers";
import ImageGallery from "./ImageGallery";
import ImageActions from "./ImageActions";
import PexelsGallery from "./PexelsGallery";
import AIImageControl from "./AIImageControl";
import { setAllMediaToSlide } from "../../../store/reducers/slideListSlice";
import { shallowEqual } from "react-redux";
import { useAuth } from "../../../context/AuthContext";
import AnimationControl from "../AnimationControl";

const ImagePanel = () => {
    const {user} = useAuth();
    const {activeObject, canvas} = useSelector(state => state.fabricCanvas);
    const { activeSlide } = useSelector(state => state.slideList, shallowEqual);
    const inputRef = useRef(null);
    const dispatch = useDispatch();
    const [uploadStatus, setUploadStatus] = useState(false);
    const [selectedTab, setSelectedTab] = useState('uploads');

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    const handleUploadImageClick = () => {
        inputRef.current.click();
    }

    const handleImageUpload = (event) => {
        setUploadStatus(true);
        const file = event.target.files[0];
        const fileName = `${user?.user_id}/image_list/user_upload_${uuidv4()}_${file.name}`;
        const params = {
            Bucket: S3_BUCKET_NAME,
            Key: fileName,
            Body: file,
            ContentType: file.type,
        };
        uploadToS3(file, fileName, params, dispatch).then(() => setUploadStatus(false));
    }

    const handleAddImage = (image) => {
        handleLoader(true, 'load_image_on_canvas', dispatch);
        const imageId = image?.id ? `${image?.id}-${uuidv4()}` : uuidv4();
        dispatch(setAllMediaToSlide({ type: 'image', data: {...image, id: imageId} }));
        getFabricImageFromURL(image.url, (fabricImage) => {
                fabricImage.set({
                    id: imageId,
                    borderWidth: 0,
                    borderStyle: 'none',
                    borderFill: "rgb(0,0,0)",
                    listStyle: 'none',
                    link: '',
                    jumpToSlide: 0,
                    slideId: activeSlide?.id
                });
                fabricImage.setControlsVisibility({
                    mt: false,
                    mb: false,
                });
                fabricImage.scaleToWidth(200);
                canvas.add(fabricImage);
                canvas.setActiveObject(fabricImage);
                centerFabricObject(fabricImage, canvas);
                canvas.renderAll();
                handleLoader(false, '', dispatch);
            }
            , () => {
                handleLoader(false, '', dispatch);
            }
        );
    }

    return (
        
        <div className="flex flex-col w-[280px] overflow-y-auto bg-gray-50 p-4">
            <input
                className="hidden"
                type="file"
                ref={inputRef}
                accept="image/gif, image/png, image/jpeg, image/jpg"
                onChange={handleImageUpload}
            />
            <div className="items-center justify-center">
                <label htmlFor="fontSelector" className="mb-2 block text-xxs font-medium text-gray-600">Select Image or
                    Upload</label>
                <div className="mb-2 flex items-center">
                    <div className="flex w-full items-center justify-center">
                        <button
                            className="flex h-10 flex-grow items-center justify-center text-gray-600 rounded-md border bg-white text-xxs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200"
                            onClick={handleUploadImageClick}
                        >
                            <FontAwesomeIcon icon={"cloud-arrow-up"} className="size-3 mr-2 text-gray-600"/>
                            Upload Image
                        </button>
                    </div>
                </div>
                <div className="flex items-center justify-center p-2 bg-white rounded-md rounded-b-none border border-b-0">
                    <div className={`flex w-full justify-center rounded-l-md p-2 border transition-all duration-200 active:bg-gray-200 border-r-0 ${selectedTab === 'uploads' ? 'bg-gray-100' : ''}`} onClick={() => handleTabClick('uploads')}>
                        <label
                            className={`text-xxs rounded-md text-gray-600 cursor-pointer`}>My Images</label>
                    </div>
                    <div className={`flex w-full justify-center rounded-r-md p-2 border transition-all duration-200 active:bg-gray-200 border-l-0 ${selectedTab === 'images' ? 'bg-gray-100' : ''}`} onClick={() => handleTabClick('images')}>
                        <label
                            className={`text-xxs rounded-md text-gray-600 cursor-pointer`}>Image Bank</label>
                    </div>
                </div>
                {
                    selectedTab === 'uploads' && (
                        <ImageGallery
                            handleAddImage={handleAddImage}
                            uploadStatus={uploadStatus}
                        />
                    )
                }
                {
                    selectedTab === 'images' && (
                        <PexelsGallery handleAddImage={handleAddImage}/>
                    )
                }
                {
                    activeObject && ['image','rect'].includes(activeObject.type) && (
                        <>
                            <LayerAndLinkControl/>
                            <ImageActions/>
                            <AnimationControl/>
                        </>
                    )
                }
                <AIImageControl/>
            </div>
        </div>
    )
}
export default ImagePanel;