const AWS = require('aws-sdk');
const s3 = new AWS.S3();

const addFolderToPublicAccessResource = async (bucketName, userId, projectId) => {
  try {
    // Step 1: Retrieve the current bucket policy
    const currentPolicyResponse = await s3.getBucketPolicy({ Bucket: bucketName }).promise();
    const currentPolicy = JSON.parse(currentPolicyResponse.Policy);

    // Step 2: Construct the folder ARN based on userId and projectId
    const folderArn = `arn:aws:s3:::${bucketName}/${userId}/${projectId}/*`;

    // Step 3: Find the statement that grants public access
    let publicAccessStatement = currentPolicy.Statement.find(
      (statement) => statement.Sid && statement.Sid.startsWith('PublicReadAccess')
    );

    if (!publicAccessStatement) {
      throw new Error("No public access statement found in bucket policy.");
    }

    // Step 4: Check if the folder is already in the resources array
    if (!publicAccessStatement.Resource.includes(folderArn)) {
      // If the resource is a string (single resource), convert it to an array
      if (typeof publicAccessStatement.Resource === 'string') {
        publicAccessStatement.Resource = [publicAccessStatement.Resource];
      }
      // Step 5: Append the new folder ARN to the resources array
      publicAccessStatement.Resource.push(folderArn);
    }

    // Step 6: Update the bucket policy with the modified statement
    const updatedPolicyParams = {
      Bucket: bucketName,
      Policy: JSON.stringify(currentPolicy),
    };

    await s3.putBucketPolicy(updatedPolicyParams).promise();
    console.log(`Public access added for folder: ${folderArn}`);

    // Step 7: Configure Static Website Hosting if not already configured
    const websiteParams = {
      Bucket: bucketName,
    };

    try {
      // Attempt to get the existing website configuration to check if it is already configured
      await s3.getBucketWebsite(websiteParams).promise();
      console.log(`Static website hosting is already enabled for bucket: ${bucketName}`);
    } catch (err) {
      if (err.code === 'NoSuchWebsiteConfiguration') {
        // If no static website hosting is configured, add it
        const websiteConfigParams = {
          Bucket: bucketName,
          WebsiteConfiguration: {
            IndexDocument: {
              Suffix: 'index.html',
            },
            ErrorDocument: {
              Key: 'error.html',
            },
          },
        };

        await s3.putBucketWebsite(websiteConfigParams).promise();
        console.log(`Static website hosting enabled for bucket: ${bucketName}`);
      } else {
        // Handle other errors
        throw err;
      }
    }

  } catch (error) {
    console.error('Error updating bucket policy or configuring static website hosting:', error);
  }
};

export default addFolderToPublicAccessResource;
