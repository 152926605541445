import { fabric } from "fabric";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { AlignGuidelines } from "fabric-guideline-plugin";
import {
  setActiveObject,
  setFontList,
  setLinkPopupCoords,
  setLinkPopupStatus,
  setJumpToSlidePopupStatus,
  setJumpToSlidePopupCoords,
  setGlobalFontSize,
  setVideoCoords,
  setVideoControl,
  setVideoPlay,

} from "../store/reducers/canvasSlice";
import {setActiveSlide} from "../store/reducers/slideListSlice";
import {setActiveCanvas} from "../store/reducers/canvasSlice";
import axios from "axios";
import s3B from "./s3Config";
import {
  addNewAudio,
  addNewImage,
  removeImage,
  addNewRecordedAudio,
  addNewVideo,
  removeVideo,
  pushNewImagesInPexelsList,
  pushNewVideoInPexelsList,
  setAudioList,
  setImageList,
  setNextPage,
  setNextVideoPage,
  setPexelsList,
  setPexelsVideoList,
  setRecordedAudioList,
  setVideoList,
  removeRecordedAudio,
  removeAudio,
  addNewJson,
} from "../store/reducers/imageBucketSlice";
import { v4 as uuidv4 } from "uuid";
import imageFile from "./imageFile";
import {
  setLoaderMessage,
  setLoaderVisibility,
} from "../store/reducers/loaderSlice";
import videoFile from "./videoFile";
import audioFile from "./audioFile";
import {
  addSlide,
  setSlideThumbnail,
  setAllMediaToSlide,
  toggleNewSlideAdded,
  deleteAllMediaFromSpecificSlide,
  setSlideList,
} from "../store/reducers/slideListSlice";
import Slide from "./slide";
import ScormHelper from "./scormHelper";
import { getScormIndexString } from "./scormIndex";
import { getScormXMLString } from "./scormXML";
import { getPipwerksScormWrapper } from "./pipwerksScormWrapper";
import { api } from "../api/api";

export const PEXEL_URL = "https://api.pexels.com/v1";
export const PEXEL_VIDEO_URL = "https://api.pexels.com/videos";

export const optionsToAdd = [
  "link",
  "jumpToSlide",
  "borderWidth",
  "borderStyle",
  "borderFill",
  "padding",
  "selectable",
  "controlVisibility",
  "id",
  "_cropInfo",
  "videoSrc",
  "play",
  "getElement",
  "_element",
  "name",
  "inputType",
  "hoverFill",
  "hoverColor",
  "_objects",
  "objects",
  "formData",
  "questionType",
  "slideId",
  "path",
  "width",
  "placeholder",
  "label",
  "buttonType",
  "svgPath",
  "color",
  "fontWeight",
  "cardImg",
  "labelLong",
  "borderColor",
  "start",
  "end",
  "customAnimation",
];

/**
 * Initializes a new Fabric.js canvas instance for a given slide.
 *
 * @param {Slide} slide - The slide object for which to create a Fabric.js canvas.
 * @param {number} index - The index of the slide.
 * @returns {Slide} The updated slide object with the associated Fabric.js canvas.
 */
export const initializeFabricCanvasInstance = (slide, index, canvasRef) => {
  const viewportHeight = window.innerHeight;
  const viewportWidth = window.innerWidth;
  const padding = 20;

  console.log("VievPortWidth", viewportWidth, viewportHeight);

  // Subtracting values to get available space
  const availableHeight = viewportHeight - (400 + padding);
  const availableWidth = viewportWidth - (460 + padding);

  console.log("avalibleWidth", availableWidth, availableHeight);

  // Calculate ratio
  const ratio = Math.min(availableHeight / 600, availableWidth / 800);

  console.log("Ratio", ratio);
  const getCanvasSize = canvasRef.current;
  // Ensure deleteObj and renderIcon functions are defined
  // removed this because already providing delete functionality

  /* function deleteObj(eventData, transform) {
    var target = transform.target;
    var canvas = target.canvas;
    canvas.remove(target);
    canvas.requestRenderAll();
  }

  function renderIcon(ctx, left, top, styleOverride, fabricObject) {
    // Delete icon on objects
    var size = this.cornerSize;
    ctx.fillStyle = '#ff0000';
    ctx.fillRect(left - size / 4, top - size / 4, size, size);
  }

  Define a custom control
  fabric.Object.prototype.controls.deleteControl = new fabric.Control({
    x: 0.5,
    y: -0.5,
    offsetY: 16,
    cursorStyle: 'pointer',
    mouseUpHandler: deleteObj,
    render: renderIcon,
    cornerSize: 8,
  }); */
  fabric.Object.prototype.transparentCorners = false;
  fabric.Object.prototype.cornerColor = "#0191F7";
  fabric.Object.prototype.cornerStyle = "circle";
  fabric.Object.prototype.cornerSize = 5;

  /**
   * Creates a new Fabric.js canvas with specified properties.
   *
   * @type {fabric.Canvas}
   */
  const newCanvas = new fabric.Canvas(`slide_deck_canvas_${slide.getId()}`, {
    width: getCanvasSize.offsetWidth - 40,
    height: getCanvasSize.offsetHeight - 40,
    preserveObjectStacking: true,
    backgroundColor: "#ffffff",
    uniformScaling: false,
    lockScalingFlip: true,
    fireRightClick: true,
    fireMiddleClick: true,
    slideId: slide?.id,
  });

  newCanvas.setZoom(ratio);

  const boundingRect = new fabric.Rect({
    id: "boundingRect",
    width: getCanvasSize.offsetWidth - 40,
    height: getCanvasSize.offsetHeight - 40,
    selectable: false,
    fill: null,
    isBoundingRect: true,
    evented: false,
  });

  // Initialize aligning guidelines for the canvas with the new plugin
  // eslint-disable-next-line
  const guideline = new AlignGuidelines({
    canvas: newCanvas, // Pass the newly created canvas instance
    aligningOptions: {
      lineColor: "red",
      lineWidth: 0.1,
      lineMargin: 0,
    },
  });
  guideline.init();
  centerFabricObject(boundingRect, newCanvas);
  newCanvas.add(boundingRect);

  newCanvas.renderAll();

  if (!slide.hasOwnProperty("isDuplicate") && !slide.thumbnail) {
    let thumbnail = createCanvasImage(newCanvas);
    slide.setThumbnail(thumbnail);
  }

  // Set the canvas on the associated slide.
  slide.setCanvas(newCanvas);

  // Set the canvas as a global variable if it's the first slide.
  if (index === 0) {
    window.fabricCanvas = newCanvas;
  }

  // Return the updated slide object.
  return slide;
};

export const deleteObj = (canvas, dispatch) => {
  if (canvas) {
    const activeObject = canvas.getActiveObject();
    canvas.discardActiveObject();
    if (activeObject) {
      dispatch?.(
        deleteAllMediaFromSpecificSlide({
          id: activeObject?.id,
          slideId: activeObject?.slideId,
          type: activeObject?.type === "svg" ? "shape" : activeObject?.type,
        })
      );
      if (activeObject.type === "activeSelection") {
        const groupObjects = [...activeObject._objects];
        groupObjects.forEach((object) => {
          if (object.name === "video") object.stop();
          canvas.remove(object).renderAll();
        });
      } else {
        if (activeObject.name === "video") activeObject.stop();
        canvas.remove(activeObject);
      }
      canvas.renderAll();
    }
  }
};

/**
 * Initializes Fabric.js events for a given canvas, enabling keyboard shortcuts and dispatching actions on selection events.
 *
 * @param {fabric.Canvas} canvas - The Fabric.js canvas for which to initialize events.
 * @param {function} dispatch - The dispatch function from a Redux store to dispatch actions.
 */
export const initFabricEvents = (canvas, dispatch, isNewSlide = false) => {
  /**
   * Event handlers for Fabric.js canvas selection events.
   */
  canvas.on({
    /**
     * Event triggered when one or more objects are selected.
     */
    "selection:created": () => {
      const activeObject = canvas.getActiveObject();
      handleVideoCoords({ target: activeObject }, dispatch, canvas);
      dispatch(setActiveObject(activeObject));
      dispatch(
        setLinkPopupCoords(
          getMiddleBottomPositionRelativeToViewport(canvas, activeObject)
        )
      );
      dispatch(
        setJumpToSlidePopupCoords(
          getMiddleBottomPositionRelativeToViewport(canvas, activeObject)
        )
      );
      changeLinkPopupStatus(canvas, true, dispatch);
      changeJumpToSlidePopupStatus(canvas, true, dispatch);
    },

    /**
     * Event triggered when the selection is cleared.
     */
    "selection:cleared": () => {
      dispatch(setActiveObject(null));
      dispatch(setVideoControl(false));
    },

    /**
     * Event triggered when the selection is updated.
     */
    "selection:updated": (e) => {
      const activeObject = canvas.getActiveObject();
      handleVideoCoords(
        { target: activeObject, deselected: e.deselected[0] },
        dispatch,
        canvas
      );
      dispatch(setActiveObject(activeObject));
      dispatch(
        setLinkPopupCoords(
          getMiddleBottomPositionRelativeToViewport(canvas, activeObject)
        )
      );
      dispatch(
        setJumpToSlidePopupCoords(
          getMiddleBottomPositionRelativeToViewport(canvas, activeObject)
        )
      );
    },

    /**
     * Event triggered after canvas creates an update after rendering.
     */
    "after:render": () => {
      renderObjectBorders(canvas);
    },

    "object:moving": () => {
      const activeObject = canvas.getActiveObject();
      handleVideoCoords({ target: activeObject }, dispatch, canvas);
      changeLinkPopupStatus(canvas, false, dispatch);
      changeJumpToSlidePopupStatus(canvas, false, dispatch);
      dispatch(setVideoControl(false));
    },

    "object:modified": () => {
      const activeObject = canvas.getActiveObject();
      handleVideoCoords({ target: activeObject }, dispatch, canvas);
      createSlideThumbnail(dispatch, canvas);
      triggerFontChange(canvas, dispatch);
      changeLinkPopupStatus(canvas, true, dispatch);
      changeJumpToSlidePopupStatus(canvas, true, dispatch);
    },

    "object:resizing": () => {
      changeLinkPopupStatus(canvas, false, dispatch);
      changeJumpToSlidePopupStatus(canvas, false, dispatch);
      dispatch(setVideoControl(false));
    },

    "object:scaling": () => {
      const activeObject = canvas.getActiveObject();
      handleVideoCoords({ target: activeObject }, dispatch, canvas);
      changeLinkPopupStatus(canvas, false, dispatch);
      changeJumpToSlidePopupStatus(canvas, false, dispatch);
      dispatch(setVideoControl(false));
    },

    "object:rotating": () => {
      changeLinkPopupStatus(canvas, false, dispatch);
      changeJumpToSlidePopupStatus(canvas, false, dispatch);
    },

    "object:added": () => {
      if (!isNewSlide) {
        createSlideThumbnail(dispatch, canvas);
      }
    },

    "object:removed": () => {
      createSlideThumbnail(dispatch, canvas);
    },
  });
};
/**
 * Handles the position of play pause buttons on canvas
 * @param e
 * @param dispatch
 * @param canvas
 */

const handleVideoCoords = (e, dispatch, canvas) => {
  if (e.target && e.target.name === "video") {
    if (e.deselected && e.deselected.name === "video") {
      e.deselected.pause();
    }
    if (!canvas.isPlaying) dispatch(setVideoPlay(false));
    dispatch(setVideoControl(true));
    const videoCoords = getMiddleCenterPositionRelativeToViewport(
      canvas,
      e.target
    );
    dispatch(setVideoCoords(videoCoords));
  }
};

const createSlideThumbnail = (dispatch, canvas) => {
  let newThumbnail = createCanvasImage(canvas);
  dispatch(setSlideThumbnail({ newThumbnail, slideId: canvas.slideId }));
};

/**
 * Triggers font change on right panel
 * @param canvas
 * @param dispatch
 */
const triggerFontChange = (canvas, dispatch) => {
  let obj = canvas.getActiveObject();
  if (obj && obj.type === "textbox") {
    dispatch(setGlobalFontSize(obj.fontSize));
  }
};

const getMiddleBottomPositionRelativeToViewport = (canvas, fabricObject) => {
  if (!fabricObject) return;
  // Get the bounding box of the Fabric object
  const boundingBox = fabricObject.getBoundingRect();

  const middleBottomX = boundingBox.left;
  const middleBottomY = boundingBox.top + boundingBox.height + 20;

  // Convert bounding box coordinates to viewport coordinates
  const viewportOffset = canvas._offset;
  const viewportX = viewportOffset.left + middleBottomX;
  const viewportY = viewportOffset.top + middleBottomY;

  return { x: viewportX, y: viewportY };
};

const getMiddleCenterPositionRelativeToViewport = (canvas, fabricObject) => {
  if (!fabricObject) return;
  // Get the bounding box of the Fabric object
  const boundingBox = fabricObject.getBoundingRect();

  const middleBottomX = boundingBox.left + boundingBox.width / 2 - 10;
  const middleBottomY = boundingBox.top + boundingBox.height / 2 - 10;

  // Convert bounding box coordinates to viewport coordinates
  const viewportOffset = canvas._offset;
  const viewportX = viewportOffset.left + middleBottomX;
  const viewportY = viewportOffset.top + middleBottomY;

  return { x: viewportX, y: viewportY };
};

export const changeLinkPopupStatus = (canvas, status, dispatch) => {
  const activeObject = canvas.getActiveObject();
  dispatch(
    setLinkPopupCoords(
      getMiddleBottomPositionRelativeToViewport(canvas, activeObject)
    )
  );

  if (activeObject.type === "activeSelection") {
    dispatch(setLinkPopupStatus(false));
    return;
  }

  if (activeObject.link?.length > 0) {
    dispatch(setLinkPopupStatus(status));
  }
};

export const changeJumpToSlidePopupStatus = (canvas, status, dispatch) => {
  const activeObject = canvas.getActiveObject();
  dispatch(
    setJumpToSlidePopupCoords(
      getMiddleBottomPositionRelativeToViewport(canvas, activeObject)
    )
  );

  if (activeObject.type === "activeSelection") {
    dispatch(setJumpToSlidePopupStatus(false));
    return;
  }

  if (activeObject.jumpToSlide > 0) {
    dispatch(setJumpToSlidePopupStatus(status));
  }
};

/**
 * Loads Google Fonts asynchronously and adds them to the document using the FontFace API.
 *
 * @param {function} dispatch - The dispatch function from a Redux store to dispatch actions.
 * @returns {Array} An array containing the loaded fonts.
 */
export const loadGoogleFonts = async (dispatch) => {
  /**
   * Retrieves a list of Google Fonts from an API.
   *
   * @type {Array} An array containing information about available Google Fonts.
   */
  const googleFontResponseList = await getGoogleFonts();

  /**
   * A list to store information about the loaded fonts.
   *
   * @type {Array}
   */
  const fontList = [];

  for (let i = 0; i < googleFontResponseList.length - 1; i++) {
    fontList.push({
      family: googleFontResponseList[i].family,
      variants: googleFontResponseList[i].variants,
      files: googleFontResponseList[i].files,
    });

    // Extract the URL for the regular variant of the font
    const fontUrl = googleFontResponseList[i].files["regular"];
    const secureFontUrl = fontUrl.replace(/^http:/, "https:");

    // Use FontFace API to create a FontFace object
    const fontFace = new FontFace(
      googleFontResponseList[i].family,
      `url('${secureFontUrl}') format('truetype')`
    );

    // Load the FontFace
    await fontFace.load();

    // Add the FontFace to the document
    document.fonts.add(fontFace);
  }

  // Dispatch an action to update the Redux store with the loaded font list
  dispatch(setFontList(fontList));

  // Return the loaded font list
  return fontList;
};

/**
 * Sends the active object backward in the canvas object stacking order.
 *
 * @param {fabric.Canvas} canvas - The Fabric.js canvas containing the active object.
 */
export const sendBackward = (canvas) => {
  /**
   * The currently selected (active) object on the canvas.
   *
   * @type {fabric.Object | null}
   */
  const activeObject = canvas.getActiveObject();

  // Check if there is an active object
  if (activeObject) {
    // Send the active object backward in the object stacking order
    canvas.sendBackwards(activeObject, true);
  }

  // Render the canvas to apply the changes
  canvas.renderAll();
};

/**
 * Brings the active object forward in the canvas object stacking order.
 *
 * @param {fabric.Canvas} canvas - The Fabric.js canvas containing the active object.
 */
export const sendForward = (canvas) => {
  /**
   * The currently selected (active) object on the canvas.
   *
   * @type {fabric.Object | null}
   */
  const activeObject = canvas.getActiveObject();

  // Check if there is an active object
  if (activeObject) {
    // Bring the active object forward in the object stacking order
    canvas.bringForward(activeObject, true);
  }

  // Render the canvas to apply the changes
  canvas.renderAll();
};

/**
 * Retrieves a list of Google Fonts from the Google Fonts API.
 *
 * @returns {Promise<Array>} A Promise that resolves to an array containing information about available Google Fonts.
 */
const getGoogleFonts = async () => {
  try {
    /**
     * The response object from the Google Fonts API.
     *
     * @type {Object}
     */
    const response = await axios.get(
      `https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env["REACT_APP_GOOGLE_FONT_API_KEY"]}`
    );

    // List of desired fonts
    const desiredFonts = [
      "Roboto",
      "Open Sans",
      "Lato",
      "Montserrat",
      "Oswald",
      "Source Sans Pro",
      "Raleway",
      "Poppins",
      "Noto Sans",
      "Ubuntu",
      "Merriweather",
      "Playfair Display",
      "PT Sans",
      "Work Sans",
      "Nunito",
      "Fira Sans",
      "Rubik",
      "Mulish",
      "Quicksand",
      "Barlow",
      "Inter",
      "Arimo",
      "Karla",
      "Titillium Web",
      "Bitter",
      "Alegreya",
      "Hind",
      "Libre Franklin",
      "Heebo",
      "Libre Baskerville",
      "Muli",
      "Exo 2",
      "Zilla Slab",
      "Josefin Sans",
      "Noto Serif",
      "Varela Round",
      "PT Serif",
      "Cabin",
      "DM Sans",
      "Asap",
      "Rajdhani",
      "Abril Fatface",
      "Cormorant",
      "Fjalla One",
      "Oxygen",
      "Orbitron",
      "Teko",
      "Anton",
      "Bree Serif",
      "Amatic SC",
    ];

    return response.data.items.filter((font) =>
      desiredFonts.includes(font.family)
    );
  } catch (error) {
    /**
     * Error object if there is an issue fetching Google Fonts.
     *
     * @type {Error}
     */
    console.error("Error fetching Google Fonts:", error);

    // Return an empty array in case of an error
    return [];
  }
};

/**
 * Renders borders for all fabric.js objects on the given canvas that have a non-zero
 * `borderWidth`. The rendering takes into account the object's position, size, rotation,
 * and border style, applying the necessary transformations and styles.
 *
 * @returns {void}
 */
const renderObjectBorders = () => {
  /**
   * Iterates through each fabric.js object on the canvas and renders borders for objects
   * with a non-zero `borderWidth`.
   *
   * @param {fabric.Object} obj - The fabric.js object to render borders for.
   * @returns {void}
   */
};

/**
 * Sets or updates the list style of a fabric.js text object within a canvas.
 *
 * @param {fabric.Text} textObject - The fabric.js text object to apply the list style.
 * @param {string} type - The type of list style ('ordered' for numbered, 'bullet' for bulleted, 'none' for no list style).
 *
 * @returns {void}
 */
export const listStyle = (textObject, type) => {
  if (textObject.type === "textbox") {
    //determines the type renders the bullet type according to that
    if (type === "bullet") {
      textObject.toggleBulletOnText();
    } else {
      textObject.toggleBulletOnText(true);
    }
  }
};
/**
 * Determines whether the object is a shape or some other type.
 * @param obj
 * @returns {boolean}
 */
export const isShape = (obj) => {
  return obj.type === "polygon" || obj.type === "circle" || obj.type === "line";
};

export const S3_BUCKET_NAME = "slidesdeck";
export const TEMP_FOLDER = "temp";

/**
 * Uploads a file to an Amazon S3 bucket and dispatches an action with the URL of the uploaded file.
 *
 * @param {File} file - The file to be uploaded.
 * @param {string} fileName - The desired name for the file in the S3 bucket.
 * @param {Object} params - The parameters to be passed to the S3 upload function.
 * @param {function} dispatch - The Redux dispatch function to dispatch actions.
 *
 * @throws {Error} If there is an error during the file upload process.
 * @returns {Promise<void>} A Promise that resolves when the file is successfully uploaded.
 */
export const uploadToS3 = async (file, fileName, params, dispatch) => {
  try {
    // Upload the file to S3
    const uploadResponse = await s3B.upload(params).promise();
    console.log("File uploaded to S3:", uploadResponse);
    // Get the URL of the uploaded file
    const imageURL = s3B.getSignedUrl("getObject", {
      Bucket: S3_BUCKET_NAME,
      Key: fileName,
      Expires: 100000,
    });
    dispatch(addNewImage(new imageFile(imageURL)));
  } catch (error) {
    console.error("Error uploading file to S3:", error);
  }
};
export const deleteImageFromS3 = async (image, dispatch, id) => {
  const parts = image.url.split("/");
  const fileNameWithParams = parts.pop(); // Extract the last part of the URL
  const fileName = decodeURIComponent(fileNameWithParams.split("?")[0]); // Remove query parameters
  try {
    const params = {
      Bucket: S3_BUCKET_NAME, // Make sure this is defined or passed in correctly
      Key: `${id}/image_list/${fileName}`,
    };

    // Delete the file from S3
    // eslint-disable-next-line
    const deleteResponse = await s3B.deleteObject(params).promise();

    // Dispatch an action to remove the image from your Redux store or state management
    // Assuming you have such an action, adjust accordingly
    dispatch(removeImage(image.id));
  } catch (error) {
    console.error("Error deleting file from S3:", error);
  }
};

export const uploadVideoToS3 = async (file, fileName, params, dispatch) => {
  try {
    // Upload the file to S3
    const uploadResponse = await s3B.upload(params).promise();
    console.log("File uploaded to S3:", uploadResponse);
    // Get the URL of the uploaded file
    const imageURL = s3B.getSignedUrl("getObject", {
      Bucket: S3_BUCKET_NAME,
      Key: fileName,
      Expires: 100000,
    });
    const newVideoFile = new videoFile(imageURL);
    await newVideoFile.generateThumbnail();
    dispatch(addNewVideo(newVideoFile));
  } catch (error) {
    console.error("Error uploading file to S3:", error);
  }
};
export const deleteVideoFromS3 = async (video, dispatch, id) => {
  const parts = video.url.split("/");
  const fileNameWithParams = parts.pop(); // Extract the last part of the URL
  const fileName = decodeURIComponent(fileNameWithParams.split("?")[0]); // Remove query parameters

  try {
    const params = {
      Bucket: S3_BUCKET_NAME, // Make sure this is defined or passed in correctly
      Key: `${id}/video_list/${fileName}`,
    };

    // Delete the file from S3
    // eslint-disable-next-line
    const deleteResponse = await s3B.deleteObject(params).promise();

    // Dispatch an action to remove the image from your Redux store or state management
    // Assuming you have such an action, adjust accordingly
    dispatch(removeVideo(video.id));
  } catch (error) {
    console.error("Error deleting file from S3:", error);
  }
};
export const uploadAudioToS3 = async (file, fileName, params, dispatch) => {
  try {
    // Upload the file to S3
    const uploadResponse = await s3B.upload(params).promise();
    console.log("File uploaded to S3:", uploadResponse);
    // Get the URL of the uploaded file
    const audioURL = s3B.getSignedUrl("getObject", {
      Bucket: S3_BUCKET_NAME,
      Key: fileName,
      Expires: 100000,
    });

    dispatch(addNewAudio(new audioFile(audioURL)));
  } catch (error) {
    console.error("Error uploading file to S3:", error);
  }
};
export const deleteAudioFromS3 = async (audio, dispatch, id) => {
  const parts = audio.url.split("/");
  const fileNameWithParams = parts.pop(); // Extract the last part of the URL
  const fileName = decodeURIComponent(fileNameWithParams.split("?")[0]); // Remove query parameters

  try {
    const params = {
      Bucket: S3_BUCKET_NAME, // Make sure this is defined or passed in correctly
      Key: `${id}/audio_list/${fileName}`,
    };

    // Delete the file from S3
    // eslint-disable-next-line
    const deleteResponse = await s3B.deleteObject(params).promise();

    // Dispatch an action to remove the image from your Redux store or state management
    // Assuming you have such an action, adjust accordingly
    dispatch(removeAudio(audio.id));
  } catch (error) {
    console.error("Error deleting file from S3:", error);
  }
};
export const uploadRecordedAudioToS3 = async (
  file,
  fileName,
  params,
  dispatch
) => {
  try {
    // Upload the file to S3
    const uploadResponse = await s3B.upload(params).promise();
    console.log("File uploaded to S3:", uploadResponse);
    // Get the URL of the uploaded file
    const audioURL = s3B.getSignedUrl("getObject", {
      Bucket: S3_BUCKET_NAME,
      Key: fileName,
      Expires: 100000,
    });

    dispatch(addNewRecordedAudio(new audioFile(audioURL)));
  } catch (error) {
    console.error("Error uploading file to S3:", error);
  }
};
export const deleteRecordedAudioFromS3 = async (audio, dispatch) => {
  const parts = audio.url.split("/");
  const fileNameWithParams = parts.pop(); // Extract the last part of the URL
  const fileName = decodeURIComponent(fileNameWithParams.split("?")[0]); // Remove query parameters
  try {
    const params = {
      Bucket: S3_BUCKET_NAME, // Make sure this is defined or passed in correctly
      Key: `temp/audio_list_recorded/${fileName}`,
    };

    // Delete the file from S3
    // eslint-disable-next-line
    const deleteResponse = await s3B.deleteObject(params).promise();

    // Dispatch an action to remove the image from your Redux store or state management
    // Assuming you have such an action, adjust accordingly
    dispatch(removeRecordedAudio(audio.id));
  } catch (error) {
    console.error("Error deleting file from S3:", error);
  }
};
/**
 * Fetches a list of image URLs from Pexels Api and dispatches an action with the list.
 *
 * @param {function} dispatch - The Redux dispatch function to dispatch actions.
 * @param {String} query - Search query string.
 * @param {Number} nextPage - Gets the next page based on query.
 * @throws {Error} If there is an error during the process of fetching image URLs from S3.
 * @returns {Promise<void>} A Promise that resolves when the image URLs are successfully fetched and dispatched.
 */
export const setupPexelsList = async (
  dispatch,
  query = null,
  nextPage = false
) => {
  try {
    const targetUrl =
      nextPage || `${PEXEL_URL}/search?query=${query || "people"}`;

    const response = await fetch(targetUrl, {
      headers: {
        Authorization: process.env["REACT_APP_PEXELS_API_KEY"],
      },
    });

    const data = await response.json();
    console.log(data.next_page);
    dispatch(setNextPage(data.next_page));

    const images = data.photos.map((ob) => ({
      url: ob.src.original,
      tinyUrl: ob.src.tiny,
    }));

    if (nextPage) {
      dispatch(pushNewImagesInPexelsList(images));
    } else {
      dispatch(setPexelsList(images));
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const setupAudioList = async (dispatch, id) => {
  const params = {
    Bucket: S3_BUCKET_NAME,
    Prefix: `${id}/audio_list`,
  };

  try {
    const objectsResponse = await s3B.listObjectsV2(params).promise();

    if (objectsResponse.Contents && objectsResponse.Contents.length > 0) {
      // Array to store the URLs of objects in the 'temp' folder
      const objectURLs = [];

      for (const object of objectsResponse.Contents) {
        // Get the URL of each object
        const objectURL = s3B.getSignedUrl("getObject", {
          Bucket: S3_BUCKET_NAME,
          Key: object.Key,
          Expires: 100000, // URL expiration time in seconds
        });

        objectURLs.push(new audioFile(objectURL));
      }

      console.log("Objects in temp folder:", objectURLs);

      dispatch(setAudioList(objectURLs));

      // Now, you can use objectURLs as needed in your application
    } else {
      console.log("No objects found in the temp folder.");
    }
  } catch (error) {
    console.error("Error fetching objects from S3:", error);
  }
};

export const setupRecordedAudioList = async (dispatch, id) => {
  const params = {
    Bucket: S3_BUCKET_NAME,
    Prefix: `${id}/audio_list`,
  };

  try {
    const objectsResponse = await s3B.listObjectsV2(params).promise();

    if (objectsResponse.Contents && objectsResponse.Contents.length > 0) {
      // Array to store the URLs of objects in the 'temp' folder
      const objectURLs = [];

      for (const object of objectsResponse.Contents) {
        // Get the URL of each object
        const objectURL = s3B.getSignedUrl("getObject", {
          Bucket: S3_BUCKET_NAME,
          Key: object.Key,
          Expires: 100000, // URL expiration time in seconds
        });

        objectURLs.push(new audioFile(objectURL));
      }

      console.log("Objects in temp folder:", objectURLs);

      dispatch(setRecordedAudioList(objectURLs));

      // Now, you can use objectURLs as needed in your application
    } else {
      console.log("No objects found in the temp folder.");
    }
  } catch (error) {
    console.error("Error fetching objects from S3:", error);
  }
};

export const setupPexelsVideoList = async (
  dispatch,
  query = null,
  nextPage = false
) => {
  try {
    const targetUrl =
      nextPage ||
      `${PEXEL_VIDEO_URL}/search?query=${query || "nature"}&size=small`;

    const response = await fetch(targetUrl, {
      headers: {
        Authorization: process.env["REACT_APP_PEXELS_API_KEY"],
      },
    });

    const data = await response.json();
    console.log(data.next_page);
    dispatch(setNextVideoPage(data.next_page));

    let videos = [];

    data.videos.forEach((videoData) => {
      const videoUrl = videoData.video_files[0];
      const videoThumbnail = videoData.video_pictures[0];

      videos.push({
        url: videoUrl.link,
        thumbnail: videoThumbnail.picture,
      });
    });

    if (nextPage) {
      dispatch(pushNewVideoInPexelsList(videos));
    } else {
      dispatch(setPexelsVideoList(videos));
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

/**
 * Fetches a list of image URLs from an Amazon S3 bucket and dispatches an action with the list.
 *
 * @param {function} dispatch - The Redux dispatch function to dispatch actions.
 *
 * @throws {Error} If there is an error during the process of fetching image URLs from S3.
 * @returns {Promise<void>} A Promise that resolves when the image URLs are successfully fetched and dispatched.
 */
export const setupImageList = async (dispatch, id) => {
  const params = {
    Bucket: S3_BUCKET_NAME,
    Prefix: `${id}/image_list`,
  };

  try {
    const objectsResponse = await s3B.listObjectsV2(params).promise();

    if (objectsResponse.Contents && objectsResponse.Contents.length > 0) {
      // Array to store the URLs of objects in the 'temp' folder
      const objectURLs = [];

      objectsResponse.Contents.forEach((object) => {
        // Get the URL of each object
        const objectURL = s3B.getSignedUrl("getObject", {
          Bucket: S3_BUCKET_NAME,
          Key: object.Key,
          Expires: 100000, // URL expiration time in seconds
        });

        objectURLs.push(new imageFile(objectURL));
      });

      console.log("Objects in temp folder:", objectURLs);

      dispatch(setImageList(objectURLs));

      // Now, you can use objectURLs as needed in your application
    } else {
      console.log("No objects found in the temp folder.");
    }
  } catch (error) {
    console.error("Error fetching objects from S3:", error);
  }
};

export const setupVideoList = async (dispatch, id) => {
  const params = {
    Bucket: S3_BUCKET_NAME,
    Prefix: `${id}/video_list`,
  };

  try {
    const objectsResponse = await s3B.listObjectsV2(params).promise();

    if (objectsResponse.Contents && objectsResponse.Contents.length > 0) {
      // Array to store the URLs of objects in the 'temp' folder
      const objectURLs = [];

      for (const object of objectsResponse.Contents) {
        // Get the URL of each object
        const objectURL = s3B.getSignedUrl("getObject", {
          Bucket: S3_BUCKET_NAME,
          Key: object.Key,
          Expires: 100000, // URL expiration time in seconds
        });

        const newVideoFile = new videoFile(objectURL);
        await newVideoFile.generateThumbnail();
        objectURLs.push(newVideoFile);
      }

      console.log("Objects in temp folder:", objectURLs);

      dispatch(setVideoList(objectURLs));

      // Now, you can use objectURLs as needed in your application
    } else {
      console.log("No objects found in the temp folder.");
    }
  } catch (error) {
    console.error("Error fetching objects from S3:", error);
  }
};

/**

 * Adds a fabric image on the canvas by sending a fetch request with an additional Origin header, this is used to prevent
 the CORS issue on S3 caused by the chrome cache
 * @param {string} [path] url string of the image resource to add on canvas
 * @param {function} [callback] returns a fabric image instance to the callback
 * @param {function} [errorCallback] error callback
 */
export const getFabricImageFromURL = (path, callback, errorCallback) => {
  const image = new Image();
  let url = path;
  image.crossOrigin = "Anonymous";
  image.onload = () => {
    const fabricImage = new fabric.StaticImage(image, {});
    callback(fabricImage);
  };

  if (path.includes("?")) {
    url += `&temp-cache-${uuidv4().substring(0, 8)}`;
  } else {
    url += `?temp-cache-${uuidv4().substring(0, 8)}`;
  }

  fetch(path.includes("base64") ? path : url, {
    method: "GET",
    mode: "cors",
    referrerPolicy: "strict-origin-when-cross-origin",
    headers: {
      Origin: window.location.origin,
    },
  })
    .then((res) => {
      image.src = res.url;
    })
    .catch((error) => {
      console.error(error);
      errorCallback();
      console.error(error);
    });
};

/**
 * Overriding fabric.loadSVGFromURL to resolve cors issue from s3 bucket
 * @param url
 * @param callback
 * @param reviver
 * @param options
 */
fabric.loadSVGFromURL = function (url, callback, reviver, options) {
  url = url.replace(/^\n\s*/, "").trim();
  if (url.includes("?")) {
    url += `&temp-cache-${uuidv4().substring(0, 8)}`;
  } else {
    url += `?temp-cache-${uuidv4().substring(0, 8)}`;
  }

  new fabric.util.request(url, {
    method: "GET",
    mode: "cors",
    referrerPolicy: "strict-origin-when-cross-origin",
    headers: {
      Origin: window.location.origin,
    },
    onComplete: onComplete,
  });

  function onComplete(r) {
    var xml = r.responseXML;
    if (!xml || !xml.documentElement) {
      callback && callback(null);
      return false;
    }

    fabric.parseSVGDocument(
      xml.documentElement,
      function (results, _options, elements, allElements) {
        callback && callback(results, _options, elements, allElements);
      },
      reviver,
      options
    );
  }
};

/**
 * Handles the visibility and message of a loader by dispatching actions based on the specified status and type.
 *
 * @param {boolean} status - The visibility status of the loader (true for visible, false for hidden).
 * @param {string} type - The type of loader action to perform. Currently supported: 'load_image_on_canvas'.
 * @param {function} dispatch - The Redux dispatch function to dispatch loader-related actions.
 *
 * @returns {void} This function does not return a value.
 */
export const handleLoader = (status, type, dispatch) => {
  let message;
  switch (type) {
    case "load_image_on_canvas":
      message = "Loading image.";
      break;
    case "load_video_on_canvas":
      message = "Loading video.";
      break;
    case "load_shape_on_canvas":
      message = "Loading shape.";
      break;
    case "link_audio_to_slide":
      message = "Adding audio to slide.";
      break;
    default:
      message = "";
      break;
  }
  dispatch(setLoaderVisibility(status));
  dispatch(setLoaderMessage(message));
};

/**
 * Toggles the lockMovementX and lockMovementY properties of the given fabric.js active object within a canvas.
 *
 * @param {Object} activeObject - The fabric.js object to be manipulated (e.g., an image or shape).
 * @param {fabric.Canvas} canvas - The fabric.js canvas that contains the active object.
 *
 * @returns {void} This function does not return a value.
 */
export const lockMovementXY = (activeObject, canvas) => {
  // Toggle lockMovementX and lockMovementY properties of the active object
  activeObject.set({
    lockMovementX: !activeObject.lockMovementX,
    lockMovementY: !activeObject.lockMovementY,
  });
  canvas.renderAll();
};

export const centerFabricObject = (object, canvas) => {
  const bounding = object.getBoundingRect(true, true);
  const zoom = canvas.getZoom();
  object.set({
    top: canvas.height / zoom / 2 - bounding.height / 2,
    left: canvas.width / zoom / 2 - bounding.width / 2,
  });
  canvas.renderAll();
};

export const createCanvasImage = (canvas) => {
  return canvas.toDataURL();
};

export const reRenderCanvasObjects = (canvas, crop, cropActive = false) => {
  const objectList = canvas.getObjects();
  const activeObject = canvas.getActiveObject();
  objectList.forEach((object) => {
    if (!object.hasOwnProperty("isBoundingRect")) {
      object.clone((clonedObject) => {
        if (object.id === activeObject.id) {
          if (cropActive) {
            handleCropFabricImage(crop, clonedObject, canvas);
          }
          //canvas.setActiveObject(clonedObject);
          canvas.renderAll();
        } else {
          clonedObject.id = uuidv4();
        }
        canvas.add(clonedObject);
        canvas.remove(object);
        canvas.renderAll();
      }, optionsToAdd);
    }
  });
};

export const handleCropExceedingDimension = (crop, activeObject, canvas) => {
  const zoom = canvas.getZoom();
  if (crop.x <= activeObject.left * zoom) {
    crop.x = activeObject.left * zoom;
  }
  if (crop.y <= activeObject.top * zoom) {
    crop.y = activeObject.top * zoom;
  }
  if (
    crop.x + crop.width >
    activeObject.left * zoom + activeObject.getScaledWidth() * zoom
  ) {
    crop.x =
      activeObject.left * zoom +
      activeObject.getScaledWidth() * zoom -
      crop.width;
  }
  if (
    crop.y + crop.height >
    activeObject.top * zoom + activeObject.getScaledHeight() * zoom
  ) {
    crop.y =
      activeObject.top * zoom +
      activeObject.getScaledHeight() * zoom -
      crop.height;
  }
};

export const handleCropFabricImage = (crop, activeObject, canvas) => {};

export const handleAddVideo = async (
  video,
  canvas,
  clonedObj = null,
  dispatch = null,
  slideId
) => {
  const { url, id, left = 0, top = 0, ...restData } = video;

  const videoId = id ? id : uuidv4();
  const { start, end } = await getAudioVideoStartEndTime(url, "video");
  dispatch?.(
    setAllMediaToSlide({
      type: "video",
      data: { ...video, start, end, id: videoId },
    })
  );
  let videoE = getVideoElement(url);
  if (dispatch) dispatch(setLoaderVisibility(true));
  if (dispatch) dispatch(setLoaderMessage("loading video..."));
  videoE.addEventListener("loadeddata", function () {
    if (dispatch) dispatch(setLoaderVisibility(false));
    if (dispatch) dispatch(setLoaderMessage(""));
    let fabVideo = new fabric.StaticVideo(videoE, {
      left: left || 0,
      top: top || 0,
      videoSrc: video,
      name: "video",
      type: "video",
      id: videoId,
      slideId,
      start,
      end,
      ...restData,
    });
    if (clonedObj) {
      fabVideo.set({
        width: clonedObj.width,
        height: clonedObj.height,
        scaleX: clonedObj.scaleX,
        scaleY: clonedObj.scaleY,
        left: clonedObj.left,
        top: clonedObj.top,
      });
    }
    console.log("VideoSize", fabVideo.width, fabVideo.height);
    canvas.add(fabVideo);
    console.log("fabVideofabVideo", fabVideo);

    fabVideo?.play(canvas);
    fabVideo?.stop(canvas);
    if (!clonedObj) canvas.setActiveObject(fabVideo);
    setTimeout(() => {
      canvas.requestRenderAll();
    }, 200);
  });
};
/**
 * Stops all the videos on the canvas
 * @param canvas
 */
export const stopAllVideos = (canvas) => {
  let objs = canvas.getObjects();
  for (let ind = 0; ind < objs.length; ind++) {
    let cObj = objs[ind];
    if (cObj.name === "video") {
      cObj.stop();
    }
  }
};

export const getVideoElement = (url) => {
  let videoE = document.createElement("video");
  videoE.crossOrigin = "anonymous";

  // Listen for when the video's metadata is loaded
  videoE.addEventListener("loadedmetadata", function () {
    // If the video width is greater than 700, scale it down to 700 and adjust the height to maintain the aspect ratio
    if (videoE.videoWidth > 700) {
      let aspectRatio = videoE.videoHeight / videoE.videoWidth;
      videoE.width = 700;
      videoE.height = 700 * aspectRatio; // Calculate the new height based on the aspect ratio
    } else {
      // Else, set to actual size
      videoE.width = videoE.videoWidth;
      videoE.height = videoE.videoHeight;
    }
  });

  let source = document.createElement("source");
  source.src = url;
  source.type = "video/mp4";
  videoE.appendChild(source);

  // Load the video
  videoE.load();

  return videoE;
};

export const loadVideoResource = (videoSrc) =>
  new Promise((resolve, reject) => {
    let videoE = document.createElement("video");
    videoE.crossOrigin = "anonymous";
    videoE.muted = true;
    let source = document.createElement("source");
    source.src = videoSrc;
    source.type = "video/mp4";
    videoE.appendChild(source);

    videoE.addEventListener("loadedmetadata", () => {
      videoE.currentTime = 1;
    });

    videoE.addEventListener("seeked", () => {
      resolve(videoE);
    });

    videoE.addEventListener("error", (event) => {
      reject(event.error);
    });
  });
export const captureFrame = (video) =>
  new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
    URL.revokeObjectURL(video.src);
    const data = canvas.toDataURL({
      format: "jpeg",
      quality: 0.8,
      multiplier: 1,
    });
    resolve(data);
  });

export const createNewSlide = (
  dispatch,
  slideListLength,
  projectId,
  showAlert,
  shouldReturnNewSlide = false
) => {
  const newSlide = new Slide({ slideCount: slideListLength });
  const body = {
    data: {
      objects: "[]",
      name: newSlide.name,
      project_id: projectId,
      slide_id: newSlide.id,
    },
  };
  addNewSlide(body, showAlert);
  dispatch(addSlide(newSlide));
  dispatch(toggleNewSlideAdded());
  if (shouldReturnNewSlide) {
    return newSlide;
  }
};

export const duplicateSlideData = (
  slideToDuplicate,
  dispatch,
  slideList,
  projectId,
  showAlert
) => {
  const newSlide = new Slide({ slideCount: slideList.length });
  newSlide.name = slideToDuplicate.name + " (Copy)";
  newSlide.audio = slideToDuplicate.getDuplicateAudioList();
  newSlide.thumbnail = slideToDuplicate.thumbnail;
  newSlide.isDuplicate = true;
  newSlide.duplicatedSlide = slideToDuplicate;
  const scormHelper = new ScormHelper(slideList);
  const jsonData = scormHelper.getStringifyJSON();
  const currentSlideJson = jsonData.find((i) => i.id === slideToDuplicate.id);
  const body = {
    data: {
      name: newSlide.name,
      project_id: projectId,
      slide_id: newSlide.id,
      objects: JSON.stringify(currentSlideJson.media),
      thumbnail: newSlide.thumbnail,
    },
  };
  addNewSlide(body, showAlert);
  dispatch(addSlide(newSlide));
  dispatch(toggleNewSlideAdded());
};

export const duplicateSlideCanvas = async (newSlide) => {
  const duplicatedList = await getDuplicatedObjectList(
    newSlide.duplicatedSlide
  );
  const newSlideCanvasInstance = newSlide.canvas;
  duplicatedList.forEach((object) => {
    if (object.name === "video") {
      handleAddVideo(object.videoSrc, newSlideCanvasInstance, object);
    } else {
      newSlideCanvasInstance.add(object);
    }
    newSlideCanvasInstance.renderAll();
  });
  delete newSlide.isDuplicate;
  delete newSlide.duplicatedSlide;
};

const getDuplicatedObjectList = (slideToDuplicate) => {
  return new Promise((resolve) => {
    const duplicatedList = [];
    const canvasObjectList = [...slideToDuplicate.canvas._objects].filter(
      (obj) => obj?.id !== "boundingRect"
    );
    for (const obj of canvasObjectList) {
      obj.clone((clonedObject) => {
        duplicatedList.push(clonedObject);
        if (duplicatedList.length === canvasObjectList.length) {
          resolve(duplicatedList);
        }
      }, optionsToAdd);
    }
  });
};

export const extractCategorizedMedia = (slideList) => {
  // Initialize storage for categorized media URLs
  const categorizedMediaUrls = {
    video: [],
    image: [],
    svg: [],
    audio: [],
  };

  // Iterate through each slide to extract and categorize media URLs
  slideList.forEach((slide) => {
    // Assume each slide has a media property that is an array of media objects
    if (slide.objects) {
      slide.objects.forEach((mediaItem) => {
        // Check if the media item has a type that matches the keys in categorizedMediaUrls
        if (mediaItem.type && categorizedMediaUrls.hasOwnProperty(mediaItem.type)) {
          categorizedMediaUrls[mediaItem.type].push(mediaItem.src);
        }
      });
    }
  });

  console.log("MediaList", categorizedMediaUrls);
  return categorizedMediaUrls;
};


export const createSCORMFiles = async (slideList, deployToLMS = false, userId, projectId) => {
  
  
  const scormHelper = new ScormHelper(slideList);
  const indexString = getScormIndexString(
    "Reveal",
    scormHelper.getStringifyJSON(),
    false
  );
  const xmlString = getScormXMLString("Reveal");
  const zip = new JSZip();

  // Initialize storage for categorized media URLs
  const categorizedMediaUrls = {
    video: [],
    image: [],
    svg: [],
    audio: [],
  };

  // Extract and categorize media URLs from slideMediaDataList
  scormHelper.slideMediaDataList.forEach((slide) => {
    slide.media.forEach((mediaItem) => {
      if (
        mediaItem.type &&
        categorizedMediaUrls.hasOwnProperty(mediaItem.type)
      ) {
        categorizedMediaUrls[mediaItem.type].push(mediaItem.src);
      }
    });
  });
  console.log("MediaList", categorizedMediaUrls);

  // Define the function to fetch and add media files based on their type
  const addMediaFilesToZip = async (mediaUrls, folder) => {
    for (const url of mediaUrls) {
      if (typeof url !== "string") {
        console.error("URL is not a string:", url);
        continue; // Skip this iteration if the url is not a string
      }

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Network response was not ok for ${url}`);
        }
        const content = await response.blob();
        const urlSegments = url.split("/");
        const fullFileName = urlSegments.pop(); // Get the last segment as the file name
        const fileName = fullFileName.split("?")[0]; // Remove any query parameters
        zip.file(`${folder}/${fileName}`, content); // Add the file to the zip in the specified folder
      } catch (error) {
        console.error("Error fetching or adding file:", url, error);
      }
    }
  };
  // Fetch and add categorized media files to the zip
  await addMediaFilesToZip(categorizedMediaUrls.video, "media/video", zip);
  await addMediaFilesToZip(categorizedMediaUrls.image, "media/images", zip);
  await addMediaFilesToZip(categorizedMediaUrls.svg, "media/svg", zip);
  await addMediaFilesToZip(categorizedMediaUrls.audio, "media/audio", zip);

  // Add static files and external resources
  const staticFiles = [
    {
      name: "assets/js/fabric.min.js",
      url: "https://cdnjs.cloudflare.com/ajax/libs/fabric.js/4.3.0/fabric.min.js",
    },
    {
      name: "assets/css/reset.min.css",
      url: "https://cdnjs.cloudflare.com/ajax/libs/reveal.js/4.3.0/reset.min.css",
    },
    {
      name: "assets/css/reveal.min.css",
      url: "https://cdnjs.cloudflare.com/ajax/libs/reveal.js/4.3.0/reveal.min.css",
    },
    {
      name: "assets/css/black.min.css",
      url: "https://cdnjs.cloudflare.com/ajax/libs/reveal.js/4.3.0/theme/black.min.css",
    },
    {
      name: "assets/js/reveal.min.js",
      url: "https://cdnjs.cloudflare.com/ajax/libs/reveal.js/4.3.0/reveal.min.js",
    },
    {
      name: "assets/css/fonts.css",
      url: "https://fonts.googleapis.com/css?family=Roboto:400,700|Open+Sans:400,700|Lato:400,700|Montserrat:400,700|Oswald:400,700|Source+Sans+Pro:400,700|Raleway:400,700|Poppins:400,700|Noto+Sans:400,700|Ubuntu:400,700|Merriweather:400,700|Playfair+Display:400,700|PT+Sans:400,700|Work+Sans:400,700|Nunito:400,700|Fira+Sans:400,700|Rubik:400,700|Mulish:400,700|Quicksand:400,700|Barlow:400,700|Inter:400,700|Arimo:400,700|Karla:400,700|Titillium+Web:400,700|Bitter:400,700|Alegreya:400,700|Hind:400,700|Libre+Franklin:400,700|Heebo:400,700|Libre+Baskerville:400,700|Muli:400,700|Exo+2:400,700|Zilla+Slab:400,700|Josefin+Sans:400,700|Noto+Serif:400,700|Varela+Round:400,700|PT+Serif:400,700|Cabin:400,700|DM+Sans:400,700|Asap:400,700|Rajdhani:400,700|Abril+Fatface:400,700|Cormorant:400,700|Fjalla+One:400,700|Oxygen:400,700|Orbitron:400,700|Teko:400,700|Anton:400,700|Bree+Serif:400,700|Amatic+SC:400,700",
    },
  ];

  // Fetch and add static files to the zip
  await Promise.all(
    staticFiles.map(async ({ name, url }) => {
      const response = await fetch(url);
      const content = await response.blob(); // Assuming static files are binary data
      zip.file(name, content);
    })
  );

  // Add other necessary files to the zip
  zip.file("index.html", indexString);
  zip.file("imsmanifest.xml", xmlString);
  zip.file("assets/js/scormApi.js", getPipwerksScormWrapper());

  // Generate the zip file
  const content = await zip.generateAsync({ type: "blob" });

  // Download the zip file
  saveAs(content, `scorm_package${Date.now()}.zip`);

  // If deployToLMS flag is true, upload the files to S3
  if (deployToLMS) {
    const folderName = `${userId}/${projectId}_${Date.now()}/`;

    zip.forEach(async (relativePath, file) => {
      try {
        const fileContent = await file.async("blob");
        const params = {
          Bucket: S3_BUCKET_NAME,
          Key: `${folderName}${relativePath}`,
          Body: fileContent,
          ContentType: fileContent.type,
        };

        await s3B.upload(params).promise();
        console.log(`Uploaded ${relativePath} to S3`);
      } catch (error) {
        console.error(`Error uploading ${relativePath} to S3:`, error);
      }
    });
  }
};

//     // List of URLs to fetch
//     const urls = [
//         { name: 'assets/js/fabric.min.js', url: 'https://cdnjs.cloudflare.com/ajax/libs/fabric.js/4.3.0/fabric.min.js' },
//         { name: 'assets/css/reset.min.css', url: 'https://cdnjs.cloudflare.com/ajax/libs/reveal.js/4.3.0/reset.min.css' },
//         { name: 'assets/css/reveal.min.css', url: 'https://cdnjs.cloudflare.com/ajax/libs/reveal.js/4.3.0/reveal.min.css' },
//         { name: 'assets/css/black.min.css', url: 'https://cdnjs.cloudflare.com/ajax/libs/reveal.js/4.3.0/theme/black.min.css' },
//         { name: 'assets/js/reveal.min.js', url: 'https://cdnjs.cloudflare.com/ajax/libs/reveal.js/4.3.0/reveal.min.js' },
//         { name: 'assets/css/fonts.css', url:'https://fonts.googleapis.com/css?family=Roboto:400,700|Open+Sans:400,700|Lato:400,700|Montserrat:400,700|Oswald:400,700|Source+Sans+Pro:400,700|Raleway:400,700|Poppins:400,700|Noto+Sans:400,700|Ubuntu:400,700|Merriweather:400,700|Playfair+Display:400,700|PT+Sans:400,700|Work+Sans:400,700|Nunito:400,700|Fira+Sans:400,700|Rubik:400,700|Mulish:400,700|Quicksand:400,700|Barlow:400,700|Inter:400,700|Arimo:400,700|Karla:400,700|Titillium+Web:400,700|Bitter:400,700|Alegreya:400,700|Hind:400,700|Libre+Franklin:400,700|Heebo:400,700|Libre+Baskerville:400,700|Muli:400,700|Exo+2:400,700|Zilla+Slab:400,700|Josefin+Sans:400,700|Noto+Serif:400,700|Varela+Round:400,700|PT+Serif:400,700|Cabin:400,700|DM+Sans:400,700|Asap:400,700|Rajdhani:400,700|Abril+Fatface:400,700|Cormorant:400,700|Fjalla+One:400,700|Oxygen:400,700|Orbitron:400,700|Teko:400,700|Anton:400,700|Bree+Serif:400,700|Amatic+SC:400,700'}
//     ];

//     try {
//         const fetchPromises = urls.map(async ({ name, url }) => {
//             const response = await fetch(url);
//             if (!response.ok) {
//                 throw new Error(`Network response was not ok for ${url}`);
//             }
//             const text = await response.text();
//             zip.file(name, text);
//             for (const { name, url } of mediaUrls) {
//                 const response = await fetch(url);
//                 if (!response.ok) throw new Error(`Failed to fetch ${url}`);
//                 const content = await response.blob(); // Assuming media files are binary data
//                 zip.file(name, content);
//             }
//         });

//         // Wait for all fetch operations to complete
//         //await Promise.all(fetchPromises);

//         // Add index.html file to the zip
//         zip.file('index.html', indexString);

//         // Add imsmanifest.xml file to the zip
//         zip.file('imsmanifest.xml', xmlString);

//         zip.file('assets/js/scormApi.js', getPipwerksScormWrapper());

//         console.log('MediaList', mediaUrls);
//         mediaUrls.forEach(media => {
//             if (media.type === 'svg') {
//                 // Assuming media.name is the file name and media.content is the SVG XML content as a string
//                 zip.file(media.name, media.path);
//             }
//         });

//         // Generate the zip content
//         const content = await zip.generateAsync({ type: 'blob' });

//         // Save the zip file
//         saveAs(content, 'scorm_files.zip');
//     } catch (error) {
//         console.error('Error fetching files or generating zip file:', error);
//     }
// };

export const createPreview = async (slideList) => {
  const scormHelper = new ScormHelper(slideList);
  const indexString = getScormIndexString(
    "Reveal",
    scormHelper.getStringifyJSON(),
    true
  );

  const winUrl = URL.createObjectURL(
    new Blob([indexString], { type: "text/html" })
  );

  window.open(winUrl);
};

export const uploadJsonToS3 = async (fileName, params, dispatch) => {
  try {
    // Upload the JSON data to S3
    const uploadResponse = await s3B.upload(params).promise();
    console.log("JSON data uploaded to S3:", uploadResponse);

    // Get the URL of the uploaded JSON data
    const jsonURL = s3B.getSignedUrl("getObject", {
      Bucket: S3_BUCKET_NAME,
      Key: fileName,
      Expires: 100000,
    });

    dispatch(addNewJson({ url: jsonURL, fileName }));
  } catch (error) {
    console.error("Error uploading JSON data to S3:", error);
  }
};

export const convertHexToRGB = (hexValue) => {
  // Remove # if it's present
  hexValue = hexValue.replace("#", "");

  // Parse hex values
  const r = parseInt(hexValue.substring(0, 2), 16);
  const g = parseInt(hexValue.substring(2, 4), 16);
  const b = parseInt(hexValue.substring(4, 6), 16);

  return {
    r,
    g,
    b,
    a: 1,
  };
};

export const getAudioVideoStartEndTime = async (url, type) => {
  const obj = document.createElement(type);
  obj.src = url;

  await new Promise((resolve, reject) => {
    obj.onloadedmetadata = () => resolve();
    obj.onerror = reject;
  });

  const start = 0; // Assuming obj starts from the beginning
  const end = obj.duration;

  return { start, end };
};



// Helper function to initialize slide canvases
export const setupSlideCanvases = (slideList, dispatch, canvasRef) => {
  let canvasZoom = 0;

  slideList.forEach((slide, index) => {
    if (!slide.canvas) {
      // Initialize the Fabric.js canvas instance for the slide
      initializeFabricCanvasInstance(slide, index, canvasRef);
      initFabricEvents(slide.canvas, dispatch);

      // Set the first slide as active
      if (index === 0) {
        dispatch(setActiveCanvas(slide.canvas));
        dispatch(setActiveSlide(slide));
        canvasZoom = slide.canvas.getZoom();
      }
    }
  });

  // Update the global window object with the slide list, if needed
  window.slideList = slideList;

  return canvasZoom; // Return the zoom level if needed for further adjustments
};

export const setupSlideList = async (dispatch, projectId) => {
  let objectURLs = []; // Initialize objectURLs here
  try {
    const response = await api.get(`/api/projects/${projectId}`);
    if (response?.data?.attributes?.slides?.data?.length) {
      objectURLs = [];
      const slideList = response.data.attributes.slides.data;
      slideList.forEach((obj) => {
        const slide = obj?.attributes;
        const newSlide = new Slide();
        newSlide.setId(slide.slide_id);
        newSlide.setSlideName(slide.name);
        if (slide.thumbnail) {
          newSlide.setThumbnail(slide.thumbnail || null);
        }
        newSlide.objects = JSON.parse(slide.objects);
        objectURLs.push(newSlide);
      });
      if (slideList.length === objectURLs?.length) {
        dispatch(setSlideList(objectURLs)); // Maintain the original logic
      }
    }
  } catch (error) {
    console.error("Error fetching slides:", error);
  }
  return objectURLs; // Always return the objectURLs, but preserve the original behavior
};


export const updateSlide = async (slideId, data, callBack, showAlert) => {
  try {
    const response = await api.put(`/api/slides/${slideId}`, data);
    if (response) {
      callBack?.();
      showAlert("success", "Slide updated successfully");
    }
  } catch (error) {
    callBack?.();
    showAlert(
      "error",
      error?.response?.data?.error?.message || "Error in updating a slide"
    );
  }
};

export const addNewSlide = async (data, showAlert) => {
  try {
    const response = await api.post(`/api/slides/`, data);
    if (response) {
      showAlert("success", "Slide added successfully");
    }
  } catch (error) {
    showAlert(
      "error",
      error?.response?.data?.error?.message || "Error in adding a slide"
    );
  }
};

export const deleteSlideById = async (id, callBack, showAlert) => {
  try {
    const deleteResponse = await api.delete(`/api/slides/${id}`);
    if (deleteResponse) {
      callBack?.();
      showAlert("success", "Slide deleted successfully!");
    }
  } catch (error) {
    showAlert(
      "error",
      error?.response?.data?.error?.message || "Error in deleting a slide"
    );
  }
};

export const applyAnimationFromData = (object, canvas, animationData) => {
  switch (animationData.type) {
    case "translate":
      const originalLeft = object.left; // Store original left position
      object.set("left", object.left - 100); // Set object left to 0
      canvas.renderAll(); // Re-render canvas to reflect the change
      object.animate("left", originalLeft, {
        onChange: canvas.renderAll.bind(canvas),
        duration: animationData.duration,
        easing: fabric.util.ease[animationData.easing],
      });
      break;
    case "rotate":
      object.animate("angle", object.angle + 360, {
        onChange: canvas.renderAll.bind(canvas),
        duration: animationData.duration,
        easing: fabric.util.ease[animationData.easing],
      });
      break;
    case "scale":
      const originalScaleX = object.scaleX; // Store original scaleX position
      const originalScaleY = object.scaleY; // Store original scaleY position
      object.set("scaleX", 0); // Set object scaleX to 0
      object.set("scaleY", 0); // Set object scaleY to 0
      canvas.renderAll(); // Re-render canvas to reflect the change
      object.animate("scaleX", originalScaleX, {
        onChange: canvas.renderAll.bind(canvas),
        duration: animationData.duration,
        easing: fabric.util.ease[animationData.easing],
      });
      object.animate("scaleY", originalScaleY, {
        onChange: canvas.renderAll.bind(canvas),
        duration: animationData.duration,
        easing: fabric.util.ease[animationData.easing],
      });
      break;
    case "opacity":
      object.set("opacity", 0);
      object.animate("opacity", object.opacity === 1 ? 0 : 1, {
        onChange: canvas.renderAll.bind(canvas),
        duration: animationData.duration,
        easing: fabric.util.ease[animationData.easing],
      });
      break;
    case "color":
      const originalFill = object.fill;
      object.set("fill", "#ffffff");
      object.animate("fill", originalFill, {
        onChange: canvas.renderAll.bind(canvas),
        duration: animationData.duration,
        easing: fabric.util.ease[animationData.easing],
      });
      break;
    default:
      break;
  }
};
