// eslint-disable-next-line
import { fabric } from "fabric";
import { v4 as uuidv4 } from "uuid";

export const getScormIndexString = (
  title = "Reveal",
  slideListData,
  isPreview
) => {
  const slideDataScript = `
        <script>
            const slideData = ${JSON.stringify(slideListData)};
        </script>
    `;

  return `
            <!doctype html>
            <html lang="en">
            <head>
                <meta charset="utf-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no">
                <title>${title}</title>
                <link rel="stylesheet" hrer="assets/css/reset.min.css">
                <link rel="stylesheet" href="assets/css/reveal.min.css">
                <link rel="stylesheet" href="assets/css/black.min.css">
                <link rel="stylesheet" href="assets/css/fonts.css">
                <!-- Necessary scripts for fabric -->
                <script src="https://cdnjs.cloudflare.com/ajax/libs/fabric.js/4.5.0/fabric.min.js"></script>
                <!-- Link to reveal.js CSS -->
                <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/reveal.js@4.2.0/dist/reveal.min.css">
                <!-- Link SCROM API when on preview -->
                <script src="
                https://cdn.jsdelivr.net/npm/pipwerks-scorm-api-wrapper@0.1.2/index.min.js
                "></script>

                <!-- Theme for the presentation -->
                <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/reveal.js@4.2.0/dist/theme/black.min.css">

                <!-- Necessary scripts -->

                <script src="https://cdn.jsdelivr.net/npm/reveal.js@5.1.0/dist/reveal.min.js"></script>
                <script src="https://cdn.jsdelivr.net/npm/reveal.js@4.2.0/dist/notes.min.js"></script>
                <script src="https://cdn.tailwindcss.com"></script>
                <style>
                    #slides-container{
                        overflow: auto !important;
                        pointer-events: auto !important;
                    }
                    input:focus-visible {
                        outline: none;
                    }
                </style>
            </head>
            <body>
            <div class="reveal">
                <div class="slides" id="slides-container">
                    <!-- Dynamically generated sections -->
                </div>
            </div>
            <script>
                const getFabricImageFromURL = (path, callback) => {
                    const image = new Image();
                    let url = path;
                    image.crossOrigin = '';
                    image.onload = () => {
                        const fabricImage = new fabric.Image(image,{});
                        callback(fabricImage);
                    };
                  
                    url += \`&temp-cache-${uuidv4().substring(0, 8)}\`;
                
                    fetch(
                        path.includes('base64') ? path : url,
                        {
                            method: 'GET',
                            mode: 'cors',
                            referrerPolicy: 'strict-origin-when-cross-origin',
                            headers: {
                                Origin: window.location.origin,
                            },
                        },
                    ).then((res) => {
                        image.src = res.url;
                    }).catch((error) => {
                        console.error(error);
                    });
                };
            </script>
            ${slideDataScript}
            <script>
                // Function to create Fabric canvas for a given slide
                function createFabricCanvas(slideId) {
                    const canvasId = 'canvas-' + slideId;
                    const section = document.createElement('section');
                    section.setAttribute('data-id', slideId);
                    const canvas = document.createElement('canvas');
                    canvas.id = canvasId;
                    section.appendChild(canvas);
                    document.getElementById('slides-container').appendChild(section);
                    return { fabricCanvas : new fabric.Canvas(canvasId, {
                        backgroundColor: 'white',
                        height: 400,
                        width: 600,
                        selection: false,
                        isDrawingMode: false,
                        evented: false,
                    }), section };
                }
                function applyAnimationFromData(object, canvas, animationData) {
                    // Check if the object is a Fabric object or HTML input element
                    const isHtmlElement = object instanceof HTMLElement;
                    const target = isHtmlElement ? object : canvas;

                    switch (animationData.type) {
                        case "translate":
                            if (isHtmlElement) {
                                const originalLeft = parseInt(object.style.left, 10); // Store original left position
                                object.style.left = (originalLeft - 100) + 'px'; // Move left
                                object.animate(
                                    [{ left: object.style.left }, { left: originalLeft + 'px' }],
                                    {
                                        duration: animationData.duration,
                                        easing: animationData.easing,
                                        fill: 'forwards'
                                    }
                                );
                            } else {
                                const originalLeft = object.left; // Store original left position
                                object.set("left", object.left - 100); // Set object left to 0
                                canvas.renderAll(); // Re-render canvas to reflect the change
                                object.animate("left", originalLeft, {
                                    onChange: canvas.renderAll.bind(canvas),
                                    duration: animationData.duration,
                                    easing: fabric.util.ease[animationData.easing],
                                });
                            }
                            break;

                        case "rotate":
                            if (isHtmlElement) {
                                object.animate(
                                    [{ transform: 'rotate(0deg)' }, { transform: 'rotate(360deg)' }],
                                    {
                                        duration: animationData.duration,
                                        easing: animationData.easing,
                                        fill: 'forwards'
                                    }
                                );
                            } else {
                                object.animate("angle", object.angle + 360, {
                                    onChange: canvas.renderAll.bind(canvas),
                                    duration: animationData.duration,
                                    easing: fabric.util.ease[animationData.easing],
                                });
                            }
                            break;

                        case "scale":
                            if (isHtmlElement) {
                                object.animate(
                                    [{ transform: 'scale(0)' }, { transform: 'scale(1)' }],
                                    {
                                        duration: animationData.duration,
                                        easing: animationData.easing,
                                        fill: 'forwards'
                                    }
                                );
                            } else {
                                const originalScaleX = object.scaleX; // Store original scaleX position
                                const originalScaleY = object.scaleY; // Store original scaleY position
                                object.set("scaleX", 0); // Set object scaleX to 0
                                object.set("scaleY", 0); // Set object scaleY to 0
                                canvas.renderAll(); // Re-render canvas to reflect the change
                                object.animate("scaleX", originalScaleX, {
                                    onChange: canvas.renderAll.bind(canvas),
                                    duration: animationData.duration,
                                    easing: fabric.util.ease[animationData.easing],
                                });
                                object.animate("scaleY", originalScaleY, {
                                    onChange: canvas.renderAll.bind(canvas),
                                    duration: animationData.duration,
                                    easing: fabric.util.ease[animationData.easing],
                                });
                            }
                            break;

                        case "opacity":
                            if (isHtmlElement) {
                                object.animate(
                                    [{ opacity: '0' }, { opacity: '1' }],
                                    {
                                        duration: animationData.duration,
                                        easing: animationData.easing,
                                        fill: 'forwards'
                                    }
                                );
                            } else {
                                object.set("opacity", 0);
                                object.animate("opacity", object.opacity === 1 ? 0 : 1, {
                                    onChange: canvas.renderAll.bind(canvas),
                                    duration: animationData.duration,
                                    easing: fabric.util.ease[animationData.easing],
                                });
                            }
                            break;
                        
                        case "color":
                            if (isHtmlElement) {
                                object.animate(
                                    [{ backgroundColor: '#ffffff' }, { backgroundColor: object.fill }],
                                    {
                                        duration: animationData.duration,
                                        easing: animationData.easing,
                                        fill: 'forwards'
                                    }
                                );
                            } else {
                               const originalFill = object.fill;
                               object.set("fill", "#ffffff");
                               object.animate("fill", originalFill, {
                                    onChange: canvas.renderAll.bind(canvas),
                                    duration: animationData.duration,
                                    easing: fabric.util.ease[animationData.easing],
                                });
                            }
                            break;

                        default:
                            break;
                    }
                }

                function setAnimation(object, canvas, htmlAnimation = null) {
                    if (object?.customAnimation || htmlAnimation) {
                        if (object instanceof HTMLElement) {
                            // If the object is an HTML element like inputBox
                            applyAnimationFromData(object, null, htmlAnimation);
                        } else {
                            // If it's a Fabric object
                            applyAnimationFromData(object, canvas, object.customAnimation);
                        }
                    }
                }
                // Iterate over slideData and create sections with Fabric canvases
                slideData.forEach(slide => {
                    const { fabricCanvas, section } = createFabricCanvas(slide.id);
                    const parsedCanvasJSON = JSON.parse(slide.canvasJSON);
                    //fabricCanvas.loadFromJSON(parsedCanvasJSON, fabricCanvas.renderAll.bind(fabricCanvas));
                    parsedCanvasJSON.objects.forEach(object => {
                        if(object.type === 'textbox') {
                            setTimeout(() => {
                                // Create the textbox
                                const textbox = new fabric.Text(object.text, {
                                    ...object // Spread the rest of the object properties you might have
                                });
                        
                                // Then use 'set' to apply additional properties
                                textbox.set({
                                    selectable: false, // Make it not selectable
                                    evented: false, // Make it not respond to events
                                });
                                setAnimation(textbox, fabricCanvas);
                                // Finally, add the textbox to the canvas
                                fabricCanvas.add(textbox);
                                console.log('Textbox added...');
                            }, 1000);
                        }
                        if(object.type === 'textInput') {
                            const inputRect = object.objects.find(i => i.type === 'rect');
                            const inputSvg = object.objects.find(i => i.type === 'path');
                            const inputField = object.objects.find(i => i.type === 'textbox');
                           
                            // Create the HTML input element with SVG
                            const inputBox = document.createElement('div');
                            inputBox.style.position = 'absolute';
                            inputBox.style.left = object.left + 'px';
                            inputBox.style.top = object.top + 'px';
                            inputBox.style.width = 'fit-content';
                            inputBox.style.height = object.height + 'px';
                            inputBox.style.display = 'flex';
                            inputBox.style.alignItems = 'center';
                            inputBox.style.justifyContent = 'space-between';
                            inputBox.style.padding = '5px 10px';
                            inputBox.style.boxSizing = 'border-box';
                            inputBox.style.borderRadius = inputRect.rx + 'px'; 
                            inputBox.style.borderWidth = inputRect.strokeWidth + 'px'; 
                            inputBox.style.borderStyle = 'solid'; 
                            inputBox.style.borderColor = inputRect.stroke;
                            inputBox.style.zIndex = 99 ;

                            const input = document.createElement('input');
                            input.type = 'text';
                            input.style.flex = '1';
                            input.style.border = 'none';
                            input.placeholder = inputField.text;
                            input.type = object.inputType;
                            input.style.backgroundColor = object.fill;
                            input.style.color = inputField.fill; // Text color
                            input.style.fontSize = inputField.fontSize + 'px';
                            input.style.width = object.width + 'px' ;
                            input.style.marginLeft = '10px' ;
                            input.style.height = '100%' ;

                            const svgContainer = document.createElement('div');
                            svgContainer.innerHTML = '<svg viewBox="0 0 448 512" height="20px" width="30px" xmlns="http://www.w3.org/2000/svg"><path fill="gray" d="' + inputSvg.path.map(segment => segment.join(' ')).join(' ') + '"/></svg>';
                            svgContainer.style.width = '30px';
                            svgContainer.style.height = '20px';
                            svgContainer.style.display = 'flex';
                            svgContainer.alignItems = 'center';
                            svgContainer.justifyContent = 'center';

                            // Add onChange event listener to the input
                            input.addEventListener('input', function(event) {
                                pipwerks.SCORM.set(object.id, event.target.value);
                                pipwerks.SCORM.save();
                            });

                            inputBox.appendChild(svgContainer);
                            inputBox.appendChild(input);
                            setAnimation(inputBox, null, object?.customAnimation);
                            section.appendChild(inputBox);
                        }
                        if(object.type === 'button') {
                            const buttonRect = object.objects.find( i => i.type === 'rect');
                            const buttonText = object.objects.find( i => i.type === 'textbox');
                            const buttonIcon = object.objects.find( i => i.type === 'path');
                            
                            // Create the HTML input element with SVG
                            const buttonBox = document.createElement('button');
                            buttonBox.style.position = 'absolute';
                            buttonBox.style.left = object.left + 'px';
                            buttonBox.style.top = object.top + 'px';
                            buttonBox.style.width = 'fit-content';
                            buttonBox.style.height = object.height + 'px';
                            buttonBox.style.display = 'flex';
                            buttonBox.style.alignItems = 'center';
                            buttonBox.style.justifyContent = buttonIcon ? 'space-between' : 'center';
                            buttonBox.style.padding = '5px 10px';
                            buttonBox.style.boxSizing = 'border-box';
                            buttonBox.style.borderRadius = buttonRect.rx + 'px'; 
                            buttonBox.style.borderWidth = buttonRect.strokeWidth + 'px'; 
                            buttonBox.style.borderStyle = 'solid'; 
                            buttonBox.style.borderColor = buttonRect.stroke;
                            buttonBox.style.backgroundColor = object.fill;
                            buttonBox.style.zIndex = 99 ;
                            buttonBox.style.cursor = 'pointer' ;


                            const buttonTitle = document.createElement('p');
                            buttonTitle.style.flex = '1';
                            buttonTitle.style.border = 'none';
                            buttonTitle.innerHTML = buttonText.text;
                            buttonTitle.style.color = buttonText.fill; // Text color
                            buttonTitle.style.fontSize = buttonText.fontSize + 'px';
                            buttonTitle.style.width = buttonText.width + 'px' ;
                            buttonTitle.style.marginLeft = buttonIcon ? '10px' : '0px' ;
                            buttonTitle.style.margin = '0px';

                            const svgContainer = document.createElement('div');
                            if(buttonIcon){
                                svgContainer.innerHTML = '<svg viewBox="0 0 448 512" height="20px" width="20px"  xmlns="http://www.w3.org/2000/svg"><path fill="' + buttonIcon.fill + '" d="' + buttonIcon.path.map(segment => segment.join(' ')).join(' ') + '"/></svg>';
                                svgContainer.style.width = '20px';
                                svgContainer.style.height = '20px';
                                svgContainer.style.display = 'flex';
                                svgContainer.alignItems = 'center';
                                svgContainer.justifyContent = 'center';
                                buttonBox.appendChild(svgContainer);
                            }
                            // Hover effect
                            buttonBox.addEventListener('mouseenter', function() {
                                buttonBox.style.backgroundColor = object.hoverFill; // Change background color on hover
                                    if(object.hoverColor){
                                        buttonTitle.style.color = object.hoverColor; // Change text color on hover
                                        if (buttonIcon) {
                                            svgContainer.querySelector('path').setAttribute('fill', object.hoverColor); // Change SVG path fill color on hover
                                        }
                                    }
                            });
                        
                            buttonBox.addEventListener('mouseleave', function() {
                                buttonBox.style.backgroundColor = object.fill; // Revert back to original color when mouse leaves
                                buttonTitle.style.color = buttonText.fill; // Revert back to original text color when mouse leaves
                                if (buttonIcon) {
                                    svgContainer.querySelector('path').setAttribute('fill', buttonIcon.fill); // Revert back to original SVG path fill color when mouse leaves
                                }
                            });
                            buttonBox.appendChild(buttonTitle);

                            // Add click event listener to the button
                            buttonBox.addEventListener('click', function(event) {
                                console.log('button clicked:', object.id);
                            });
                            setAnimation(buttonBox, null, object?.customAnimation);
                            section.appendChild(buttonBox);
                        }
                        if(object.type === 'card') {
                            const cardBg = object._objects[0];
                            const imagePlaceholder = object._objects[1];
                            const cardTitle = object._objects[2];
                            const cardContent = object._objects[3];
                            // Create the rectangle
                            const cardBox = new fabric.Rect({
                                ...cardBg // Spread the rest of the object properties you might have
                            });

                            const cardImg = imagePlaceholder .type === 'path' ? new fabric.Path(imagePlaceholder.path,{
                                ...imagePlaceholder, // Spread the rest of the object properties you might have
                            }) : null ;

                            const cardHeading = new fabric.Text(cardTitle.text,{
                                ...cardTitle // Spread the rest of the object properties you might have
                                
                            });
                            const cardDetail = new fabric.Text(cardContent.text,{
                                ...cardContent // Spread the rest of the object properties you might have
                                
                            });
                            cardBox.set({
                                selectable: false, // Make it not selectable
                                evented: false, // Make it not respond to events
                            });
                            if(cardImg){ 
                                cardImg.set({
                                selectable: false, // Make it not selectable
                                evented: false, // Make it not respond to events
                                });
                            }
                            cardHeading.set({
                                selectable: false, // Make it not selectable
                                evented: false, // Make it not respond to events
                            });
                            cardDetail.set({
                                selectable: false, // Make it not selectable
                                evented: false, // Make it not respond to events
                            });
                            if (object.cardImg) {
                                        fabric.Image.fromURL(object.cardImg, (fabricImage) => {
                                            // Apply the calculated scale to both width and height to maintain aspect ratio
                                        fabricImage.scaleToHeight(cardBox.height / 2);
                                        fabricImage.scaleToWidth(cardBox.width);
                                        // Update the image properties
                                        fabricImage.set({
                                            left: imagePlaceholder.left ,
                                            top: imagePlaceholder.top,
                                            originX: 'left',
                                            originY: 'top',
                                        });
                                        const clipPath = new fabric.Rect({
                                            width: fabricImage.width,
                                            height: fabricImage.height,
                                            originX: 'center',
                                            originY: 'center',
                                            rx: 30, // Adjust the radius as needed
                                            ry: 30, // Adjust the radius as needed
                                        });
                                        // Apply clip path to the image
                                        fabricImage.set('clipPath', clipPath);

                                        // Set controls visibility to hide unnecessary controls
                                        fabricImage.setControlsVisibility({
                                            mt: false, // Middle top control
                                            mb: false, // Middle bottom control
                                        });
                                        const groupedCardWithImg = new fabric.Group([cardBox, fabricImage, cardHeading, cardDetail], {
                                          left: object.left,
                                          top: object.top,
                                          selectable: false, // Make the group not selectable
                                          evented: false, // Make the group not respond to events
                                      });
                                      // Add the group to the canvas
                                      setAnimation(groupedCardWithImg, fabricCanvas);
                                      fabricCanvas.add(groupedCardWithImg);
                                });
                              }else{
                                const groupedCard = new fabric.Group([cardBox, cardImg, cardHeading, cardDetail], {
                                    left: object.left,
                                    top: object.top,
                                    selectable: false, // Make the group not selectable
                                    evented: false, // Make the group not respond to events
                                });
                            
                                // Add the group to the canvas
                                setAnimation(groupedCard, fabricCanvas);
                                fabricCanvas.add(groupedCard);
                              }
                        }

                        if(object.type === 'quiz') {
                            const formData = object.formData || {};
                            // Create inner div
                            const containerDiv = document.createElement('div');
                            containerDiv.classList.add('max-w-lg', 'mx-auto', 'bg-white', 'p-4', 'rounded-lg', 'shadow-md');
                            containerDiv.style.position = 'absolute';
                            containerDiv.style.left = object.left + 'px';
                            containerDiv.style.top = object.top + 'px';
                            containerDiv.style.zIndex = 99;

                            // Create question heading
                            const questionHeading = document.createElement('h6');
                            questionHeading.classList.add('text-[16px]', object.questionType === "true-false" ? 'text-center' : 'text-left', 'font-semibold', 'mb-4', '!text-black');
                            questionHeading.textContent = formData.question || formData.title;
                            containerDiv.appendChild(questionHeading); 

                            switch (object.questionType) {
                                case "true-false":
                                    // Create True/False buttons div
                                    const buttonsDiv = document.createElement('div');
                                    buttonsDiv.classList.add('flex', 'justify-center', 'mb-6');
                                    
                                    // Create True button
                                    const trueButton = document.createElement('button');
                                    trueButton.id = 'trueButton';
                                    trueButton.classList.add('px-4', 'py-2', 'mr-4', 'bg-blue-500', 'text-[16px]', 'text-white', 'rounded-lg', 'shadow-md', 'hover:bg-blue-600', 'focus:outline-none', 'focus:bg-blue-600');
                                    trueButton.textContent = formData.trueBtnText || 'True';
                                    
                                    // Create False button
                                    const falseButton = document.createElement('button');
                                    falseButton.id = 'falseButton';
                                    falseButton.classList.add('px-4', 'py-2', 'bg-red-500', 'text-white', 'text-[16px]', 'rounded-lg', 'shadow-md', 'hover:bg-red-600', 'focus:outline-none', 'focus:bg-red-600');
                                    falseButton.textContent = formData.falseBtnText || 'False';
                                    
                                    // Append buttons to buttons div
                                    buttonsDiv.appendChild(trueButton);
                                    buttonsDiv.appendChild(falseButton);
                                    
                                    // Create Feedback Answers div
                                    const feedbackDiv = document.createElement('div');
                                    feedbackDiv.classList.add('text-center');
                                    
                                    // Create Correct Answer Feedback div
                                    const correctFeedbackDiv = document.createElement('div');
                                    correctFeedbackDiv.id = 'correctFeedback';
                                    correctFeedbackDiv.classList.add('hidden', 'mb-4');
                                    
                                    // Create Correct Answer Feedback text
                                    const correctFeedbackText = document.createElement('p');
                                    correctFeedbackText.classList.add('text-green-600', 'font-semibold', 'text-sm', '!my-2');
                                    correctFeedbackText.textContent = 'Correct!';
                                    
                                    // Create Correct Answer Feedback message
                                    const correctFeedbackMessage = document.createElement('p');
                                    correctFeedbackMessage.classList.add('text-sm', 'text-gray-600', '!my-2');
                                    correctFeedbackMessage.textContent = formData.trueAnsPropmt || '';
                                    
                                    // Append text and message to Correct Answer Feedback div
                                    correctFeedbackDiv.appendChild(correctFeedbackText);
                                    correctFeedbackDiv.appendChild(correctFeedbackMessage);
                                    
                                    // Create Incorrect Answer Feedback div
                                    const incorrectFeedbackDiv = document.createElement('div');
                                    incorrectFeedbackDiv.id = 'incorrectFeedback';
                                    incorrectFeedbackDiv.classList.add('hidden');
                                    
                                    // Create Incorrect Answer Feedback text
                                    const incorrectFeedbackText = document.createElement('p');
                                    incorrectFeedbackText.classList.add('text-red-600', 'font-semibold', 'text-sm', '!my-2');
                                    incorrectFeedbackText.textContent = 'Incorrect!';
                                    
                                    // Create Incorrect Answer Feedback message
                                    const incorrectFeedbackMessage = document.createElement('p');
                                    incorrectFeedbackMessage.classList.add('text-sm', 'text-gray-600', '!my-2');
                                    incorrectFeedbackMessage.textContent = formData.falseAnsPropmt || '';
                                    
                                    // Append text and message to Incorrect Answer Feedback div
                                    incorrectFeedbackDiv.appendChild(incorrectFeedbackText);
                                    incorrectFeedbackDiv.appendChild(incorrectFeedbackMessage);
                                    
                                    // Append Correct and Incorrect Feedback divs to Feedback Answers div
                                    feedbackDiv.appendChild(correctFeedbackDiv);
                                    feedbackDiv.appendChild(incorrectFeedbackDiv);
                                    
                                    // Add click event listeners to the true/false buttons
                                    trueButton.addEventListener('click', () => {
                                      // Show correct feedback and hide incorrect feedback
                                      correctFeedbackDiv.classList.remove('hidden');
                                      incorrectFeedbackDiv.classList.add('hidden');
                                    });
                                    
                                    falseButton.addEventListener('click', () => {
                                      // Show incorrect feedback and hide correct feedback
                                      correctFeedbackDiv.classList.add('hidden');
                                      incorrectFeedbackDiv.classList.remove('hidden');
                                    });
                                    // Append all elements to inner div
                                    containerDiv.appendChild(buttonsDiv);
                                    containerDiv.appendChild(feedbackDiv);
                                    break;

                                case "single-selection": {
                                    // Check if options exist
                                    const options = formData.options.filter((i) => i.label) || [];
                                    if (options.length) {
                                    // Create the form element
                                    const form = document.createElement("form");
                                    
                                    // Create the div for options
                                    const optionsDiv = document.createElement("div");
                                    optionsDiv.className = "space-y-2";
                                    let answer = '';
                                    let result = { msg : '', type: '' };
                                                                    
                                    // Create the submit button
                                    const submitButton = document.createElement("button");
                                    submitButton.type = "submit";
                                    submitButton.disabled = answer === '';
                                    submitButton.classList.add('px-4', 'py-2', 'my-3', 'bg-blue-500', 'float-left', 'text-[16px]', 'text-white', 'rounded-lg', 'shadow-md', 'hover:bg-blue-600', 'focus:outline-none', 'focus:bg-blue-600');
                                    submitButton.textContent = "Submit";

                                    // Loop through options and create radio buttons
                                        options.forEach((option, index) => {
                                        const optionDiv = document.createElement("div");
                                        optionDiv.className = "flex items-center";
                                    
                                        const radioInput = document.createElement("input");
                                        radioInput.type = "radio";
                                        radioInput.id = 'option-' + index;
                                        radioInput.name = "option";
                                        radioInput.value = index;
                                        radioInput.className = "mr-2 text-[16px]";
                                        radioInput.addEventListener("change", function() {
                                            answer = index;
                                            radioInput.checked = true;
                                            submitButton.disabled = false;
                                        });
                                    
                                        const label = document.createElement("label");
                                        label.htmlFor =  'option-' + index;
                                        label.className = "select-none text-[16px] text-black text-left w-full";
                                        label.textContent = option.label;
                                    
                                        optionDiv.appendChild(radioInput);
                                        optionDiv.appendChild(label);
                                    
                                        optionsDiv.appendChild(optionDiv);
                                    });
                                    
                                    // Append options div to form
                                    form.appendChild(optionsDiv);

                                    submitButton.addEventListener("click", function(event) {
                                        event.preventDefault();
                                        if(result.type){
                                            resultParagraph.classList.remove(('text-' + result.type + '-600'));
                                        }
                                        if(answer === formData.correctAnswer){
                                            result = { msg : 'Correct Answer!', type: 'green' }
                                        }else {
                                            result = { msg : 'Incorrect Answer!', type: 'red' }
                                        }
                                        if (result.msg) {
                                            // Create paragraph element for result message
                                            resultParagraph.textContent = result.msg;
                                            resultParagraph.classList.add(('text-' + result.type + '-600'));
                                        }
                                    });

                                    // Append submit button to form
                                    form.appendChild(submitButton);
                                                                    
                                    // Append form to main div
                                    containerDiv.appendChild(form);

                                    const resultParagraph = document.createElement("p");
                                    resultParagraph.classList.add('mt-4', 'font-semibold', 'text-[16px]');
                                    // Append result paragraph to main div
                                    containerDiv.appendChild(resultParagraph);

                                    }
                                    break;
                                }
                                case "multiple-selection":{
                                    // Check if options exist
                                    const options = formData.options.filter((i) => i.label) || [];
                                    if (options.length) {
                                    // Create the form element
                                    const form = document.createElement("form");

                                    // Create the div for options
                                    const optionsDiv = document.createElement("div");
                                    optionsDiv.className = "space-y-2";
                                    let answers = []; // Changed to array for multiple selections
                                    let result = { msg: '', type: '' };

                                    // Create the submit button
                                    const submitButton = document.createElement("button");
                                    submitButton.type = "submit";
                                    submitButton.disabled = answers.length === 0; // Check if answers array is empty
                                    submitButton.classList.add('px-4', 'py-2', 'my-3', 'bg-blue-500', 'float-left', 'text-[16px]', 'text-white', 'rounded-lg', 'shadow-md', 'hover:bg-blue-600', 'focus:outline-none', 'focus:bg-blue-600');
                                    submitButton.textContent = "Submit";

                                    // Loop through options and create checkboxes
                                    options.forEach((option, index) => {
                                        const optionDiv = document.createElement("div");
                                        optionDiv.className = "flex items-center";

                                        const checkboxInput = document.createElement("input");
                                        checkboxInput.type = "checkbox"; // Change type to checkbox
                                        checkboxInput.id = 'option-' + index;
                                        checkboxInput.name = "option";
                                        checkboxInput.value = index;
                                        checkboxInput.className = "mr-2 text-[16px]";
                                        checkboxInput.addEventListener("change", function () {
                                        if (checkboxInput.checked) {
                                            answers.push(index); // Add selected option to answers array
                                        } else {
                                            const indexToRemove = answers.indexOf(index);
                                            if (indexToRemove !== -1) {
                                            answers.splice(indexToRemove, 1); // Remove deselected option from answers array
                                            }
                                        }
                                        submitButton.disabled = answers.length === 0; // Enable/disable submit button based on selections
                                        });

                                        const label = document.createElement("label");
                                        label.htmlFor = 'option-' + index;
                                        label.className = "select-none text-[16px] text-black text-left w-full";
                                        label.textContent = option.label;

                                        optionDiv.appendChild(checkboxInput);
                                        optionDiv.appendChild(label);

                                        optionsDiv.appendChild(optionDiv);
                                    });

                                    // Append options div to form
                                    form.appendChild(optionsDiv);

                                    submitButton.addEventListener("click", function (event) {
                                        event.preventDefault();
                                        if (result.type) {
                                        resultParagraph.classList.remove(('text-' + result.type + '-600'));
                                        }
                                        const isCorrect = answers.every(ans => formData.correctAnswer.includes(ans)); // Check if all selected answers are correct
                                        result = { msg: isCorrect ? 'Correct Answer!' : 'Incorrect Answer!', type: isCorrect ? 'green' : 'red' };
                                        if (result.msg) {
                                        // Create paragraph element for result message
                                        resultParagraph.textContent = result.msg;
                                        resultParagraph.classList.add(('text-' + result.type + '-600'));
                                        }
                                    });

                                    // Append submit button to form
                                    form.appendChild(submitButton);

                                    // Append form to main div
                                    containerDiv.appendChild(form);

                                    const resultParagraph = document.createElement("p");
                                    resultParagraph.classList.add('mt-4', 'font-semibold', 'text-[16px]');
                                    // Append result paragraph to main div
                                    containerDiv.appendChild(resultParagraph);
                                    }

                                    break;
                                }
                                default:
                                    break;
                            }
                           
                            
                            setAnimation(containerDiv, null, object?.customAnimation);
                            // Append container div to document body
                            section.appendChild(containerDiv);
                            
                        }
                        if(object.type === 'polygon') {
                            const polygon = new fabric.Polygon(object.points, {...object});
                            setAnimation(polygon, fabricCanvas);
                            fabricCanvas.add(polygon);
                        }
                        
                        if(object.type === 'line') {
                            const line = new fabric.Line(object.points, {...object});
                            setAnimation(line, fabricCanvas);
                            fabricCanvas.add(line);
                        }
                        
                        if(object.type === 'circle') {
                            const circle = new fabric.Circle({...object})
                            setAnimation(circle, fabricCanvas);
                            fabricCanvas.add(circle);
                        }
                        
                        if(object.type === 'image') {
                            let imagePath;
                            if (!${isPreview}) {
                                if (object.name === 'video') {
                                    const urlSegments = object.videoSrc.url.split('/');
                                    const fullFileName = urlSegments.pop(); // Get the last segment as the file name
                                    const fileName = fullFileName.split('?')[0]; // Remove any query parameters
                                    imagePath = 'media/video/' + encodeURIComponent(fileName);
                                } else {
                                    const urlSegments = object.src.split('/');
                                    const fullFileName = urlSegments.pop(); // Get the last segment as the file name
                                    const fileName = fullFileName.split('?')[0]; // Remove any query parameters
                                    imagePath = 'media/images/' + fileName;
                                }
                            } else {
                                imagePath = object.base64src;
                            }

                            fabric.Image.fromURL(imagePath, (fabricImageInstance) => {
                                const imageProps = {
                                    top: object.top,
                                    left: object.left,
                                    selectable: false,
                                    evented: false,
                                    customAnimation: object?.customAnimation
                                };
                                if (!${isPreview}) {
                                    imageProps.scaleX = object.scaleX,
                                    imageProps.scaleY= object.scaleY
                                }
                                fabricImageInstance.set(imageProps);
                                setAnimation(fabricImageInstance, fabricCanvas);
                                fabricCanvas.add(fabricImageInstance);
                            })
                        }
                    })
                                        
                    fabricCanvas.getObjects().forEach((object) => {
                        object.set({
                            selectable: false,
                            evented: false,
                        });
                        fabricCanvas.renderAll();
                    });
                });
            </script>
            <script src="assets/js/reveal.min.js"></script>
            <script>
                Reveal.initialize({
                    hash: true,
                    height: 600,
                    width: 800,
                    plugins: []
                });
                
                console.log('Pipwerks SCORM Available: ' + pipwerks.SCORM.isAvailable());

                Reveal.addEventListener('slidechanged', function(event) {
                    pipwerks.SCORM.set("cmi.core.lesson_location", event.indexh + '-' + event.indexv);
                    pipwerks.SCORM.save();

                    if (event.currentSlide === Reveal.getTotalSlides()) {
                        console.log('Lesson Complete')
                        pipwerks.SCORM.set("cmi.core.lesson_status", "completed");
                        pipwerks.SCORM.quit();
                    }
                });
            </script>
            </body>
            </html>
`;
};
