import React, { useEffect, useState } from "react";
import {
  TickMarkIcon,
  TwitterIcon,
  YoutubeIcon,
  InstagramIcon,

} from "../../../../utils/iconHelper";
import { useNavigate } from "react-router";
import { useAuth, useCheckSubscription } from "../../../../context/AuthContext";
import Footer from "../Footer/Footer";
import Newsletter from "../Newsletter/Newsletter";

const Content = () => {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth();
  const checkSubscription = useCheckSubscription();
  const [isPlanActive, setIsPlanActive] = useState(false);
  const [isYearly, setIsYearly] = useState(false);

  useEffect(() => {
    const checkUserSubscription = async () => {
      if (isAuthenticated) {
        const isActive = await checkSubscription(user);
        setIsPlanActive(isActive);
        if (isActive) {
          navigate('/projects');
        }
      }
    };
    checkUserSubscription();
  }, [isAuthenticated, user, checkSubscription, navigate]);

  const handleNavigation = async () => {
    if (isAuthenticated) {
      const isActive = await checkSubscription(user);
      if (isActive) {
        navigate("/projects");
      } else {
        navigate("/subscription-details");
      }
    } else {
      navigate("/login", { state: { redirectTo: "/subscription-details" } });
    }
  };

  const handleToggle = () => {
    setIsYearly(!isYearly);
  };

  return (
    <>
      <div>
        {/* Jumbotron with Search Bar */}
        <div className="relative text-white text-center w-full h-screen flex items-center justify-center bg-blue-900">
          <div className="absolute inset-0 w-full h-full object-cover">
            <img src="/header_image.png" alt="Header" className="w-full h-full object-cover opacity-50" />
            <img src="/component4.png" alt="Component 4" className="absolute bottom-10 left-0 w-24 h-4" /> 
          <img src="/component4.png" alt="Component 4" className="absolute top-10 opacity-50 right-0 w-24 h-4 filter brightness-125 hue-rotate-180" /> {/* Added blue filter using Tailwind */}
            <div className="absolute bottom-0 right-0 w-1/3 h-full bg-custom-skyBlue">
              <div className="flex mt-10 mb-96 ml-24 xs:ml-5 text-custom-dark">
                <a href="/" className="w-6 mx-1">
                  <InstagramIcon />
                </a>
                <a href="/" className="w-6 mx-1">
                  <TwitterIcon />
                </a>
                <a href="/" className="w-6 mx-1">
                  <YoutubeIcon />
                </a>
              </div>
            </div>
          </div>
          <div className="relative z-10 flex flex-col md:flex-row items-center justify-between w-full max-w-7xl px-6">
          
            <div className="text-left md:w-1/2">
              <h1 className="text-4xl md:text-6xl font-bold mb-4">AI Powered E-Learning Platform For Your Business</h1>
              <p className="mb-6 text-gray-300">
                Empowering Corporate Learning Everywhere with AI and Innovation: A Complete SCORM-Compatible, Browser-Based, Mobile-Friendly eLearning Solution for Every Company.
              </p>
              <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                <a href="/signup">
                  <button className="bg-custom-cyan hover:bg-custom-lightBlue text-white font-semibold py-2 px-4 rounded-full">
                    Get Started
                  </button>
                </a>
                <a href="/learn-more">
                  <button className="bg-transparent hover:bg-white text-white hover:text-blue-900 font-semibold py-2 px-4 border border-white hover:border-transparent rounded-full">
                    Learn More
                  </button>
                </a>
              </div>
            </div>
            <div className="mt-8 md:mt-0 md:w-1/2 relative">
              <div className="bg-gray-300 p-3 rounded-lg shadow-lg opacity-95">
                <img src="/screenshot.png" alt="Dashboard" className="w-full h-auto rounded-lg opacity-100" />
              </div>
            </div>
            
          </div>
          
        </div>
        <div id="about" className="px-6 md:px-0 mx-auto items-center mb-12">
          <p className="text-center text-l text-blue-600 mt-12">
            Everything you need
          </p>
          <h2 className="text-4xl px-40 xs:px-0 mt-2 mb-2 font-semibold text-gray-700 text-center">
            The most powerful <span className="text-custom-cyan font-bold">web-based e-learning </span> creation tools for your business
          </h2>
        

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mx-auto md:max-w-screen-md lg:max-w-screen-lg my-16 relative">
          <div className="bg-white rounded-xl z-10 shadow-md p-4 flex flex-col items-center mx-auto w-full md:w-64">
            <div className="flex justify-center items-center bg-gradient-to-br from-primary-100 to-primary-200 rounded-full p-2 h-12 mb-4">
              <img src="/bar-chart.gif" alt="Billing & Payments" className="w-12 h-12" />
            </div>
            <h3 className="text-center text-xl font-semibold text-gray-700">
              SCORM
            </h3>
            <p className="text-center mt-2 text-gray-500">
              Full compatibility with modern LMS Platform for SCORM compatibility.
            </p>
          </div>

          <div className="bg-white rounded-xl z-10 shadow-md p-4 flex flex-col items-center mx-auto w-full md:w-64">
            <div className="flex justify-center items-center bg-gradient-to-br from-primary-100 to-primary-200 rounded-full p-2 h-12 mb-4">
              <img src="/artificial-intelligence.gif" alt="Billing & Payments" className="w-12 h-12" />
            </div>
            <h3 className="text-center text-xl font-semibold text-gray-700">
              AI enabled
            </h3>
            <p className="text-center mt-2 text-gray-500">
              Generate text, images and videos with AI integrations.
            </p>
          </div>

          <div className="bg-white rounded-xl z-10 shadow-md p-4 flex flex-col items-center mx-auto w-full md:w-64">
            <div className="flex justify-center items-center bg-gradient-to-br from-primary-100 to-primary-200 rounded-full p-2 h-12 mb-4">
              <img src="/collaboration.gif" alt="Billing & Payments" className="w-12 h-12" />
            </div>
            <h3 className="text-center text-xl font-semibold text-gray-700">
              Collaboration
            </h3>
            <p className="text-center mt-2 text-gray-500">
              Collaborate with your team and share with your clients and
              coworkers.
            </p>
          </div>
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-1/2 h-1 bg-custom-skyBlue z-1"></div> {/* Added blue line */}
          </div>
        </div>
        <div className="flex justify-center mt-8">
          <a href="/signup">
            <button className="bg-custom-lightBlue hover:bg-custom-blue text-white font-semibold py-2 px-24 rounded-full">
              Sign Up
            </button>
          </a>
        </div>
      </div>
  <div id="focus" className="p-12 md:px-0 bg-custom-skyBlue items-center">
  <div className="items-center">
    <div className="justify-center items-center">
      <h2 className="text-4xl md:text-[40px] font-bold text-gray-700 mt-12 mb-12 text-center">
        Helping you focus on <span className="text-custom-cyan font-bold">what really matters</span>
      </h2>
      <div className="relative justify-center items-center flex">
        <div className="bg-custom-dark p-3 rounded-lg shadow-lg opacity-95">
          <img
            src="screenshot.png"
            alt="Descriptive Alt Text"
            className="w-[600px] md:w-[600px] sm:w-[400px] h-auto object-cover rounded-xl shadow-md"
          />
        </div>
        <div className="absolute flex flex-wrap justify-between items-center p-4 w-full h-full md:block xs:hidden">
          <div className="absolute top-12 left-44 flex flex-col items-center">
            <div className="bg-white p-2 rounded-xl shadow-md items-center flex flex-col">
              <img src="/ai.gif" alt="Advanced" className="w-12 h-12" />
              <p className="text-black text-sm font-bold text-center">AI Features</p>
            </div>
            
          </div>
          <div className="absolute top-12 right-44 flex flex-col items-center">
            <div className="bg-white p-2 rounded-xl shadow-md items-center flex flex-col">
              <img src="/slide.gif" alt="Interactive" className="w-12 h-12" />
              <p className="text-black text-sm font-bold text-center">Multiple<br />templates</p>
            </div>
      
          </div>
          <div className="absolute bottom-12 left-52 flex flex-col items-center">
            <div className="bg-white rounded-xl shadow-md items-center flex flex-col">
              <img src="/user-security.gif" alt="Video" className="w-12 h-12" />
              <p className="text-black text-sm font-bold text-center">Secure<br />platform</p>
            </div>
            
          </div>
          <div className="absolute bottom-8 right-40 flex flex-col items-center">
            <div className="bg-white p-2 rounded-xl shadow-md items-center flex flex-col">
              <img src="/management.gif" alt="Quiz" className="w-12 h-12" />
              <p className="text-black text-sm font-bold text-center">Easy to use</p>
            </div>
            
          </div>
        </div>
      </div>
      <p className="text-gray-500 justify-center text-center mx-60 xs:mx-0 mt-4">Forget about hard-to-use desktop software that takes ages to learn and deliver your e-learning in hours. Work smart, not hard!</p>

    </div>
      </div>
    </div>
    <div id="features" className="p-8 md:px-0 bg-custom-topBlue items-center">
  <div className="mx-28 xs:mx-0 text-center"> 
    <div className="mt-8 md:mt-0">
      <h2 className="text-4xl md:text-[40px] font-bold text-white">
        Designed to support full scope of your <span className="text-custom-cyan">e-learning</span> needs.
      </h2>
      <p className="mt-4 text-white">
        Our platform is designed to support all e-learning needs from start to finish, all in one platform. 
      </p>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2 mt-8 text-white mx-4 md:mx-16 xs:mb-12"> 
        <div className="border-l-2 border-white pl-4 xs:mb-8"> 
          <p className="font-bold">Ideas</p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut purus eget sapien.</p>
        </div>
        <div className="border-l-2 border-white pl-4 xs:mb-8"> 
          <p className="font-bold">Marketplace templates</p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut purus eget sapien.</p>
        </div>
      
        <div className="border-l-2 border-white pl-4 xs:mb-8"> 
          <p className="font-bold">AI Editor</p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut purus eget sapien.</p>
        </div>
        <div className="border-l-2 border-white pl-4 xs:mb-8"> 
          <p className="font-bold">LMS Integration</p>
          <p className="text-justify-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut purus eget sapien.</p>
        </div>
      </div>
      <img src="/workflow.png" alt="Arrow" className="w-[400px] md:w-[600px] h-auto align-center mx-auto" /> 
    </div>
  </div>
</div>
<div className="p-8 md:px-0">
  <div className="max-w-7xl mx-24 xs:mx-0 grid grid-cols-1 md:grid-cols-2 p-4">
    <div>
      <h2 className="text-3xl md:text-[40px] font-bold text-gray-700 tracking-tight">
        Compatible with all your favorite <span className="text-custom-cyan">LMS – SCORM</span>
      </h2>
      <p className="mt-4 text-gray-500">
        Full integration and support for most popular platforms if you already have LMS. If
        there's an integration missing that you need, let us know and
        we'll happily add support.
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-gray-500">
        <div>
          <ul className="mt-4 space-y-2">
            <li className="flex items-center">
              <TickMarkIcon />
              <span className="ml-2">Moodle</span>
            </li>
            <li className="flex items-center">
              <TickMarkIcon />
              <span className="ml-2">Canvas</span>
            </li>
          </ul>
        </div>
        <div>
          <ul className="mt-4 space-y-2">
            <li className="flex items-center">
              <TickMarkIcon />
              <span className="ml-2">Blackboard Learn</span>
            </li>
            <li className="flex items-center">
              <TickMarkIcon />
              <span className="ml-2">TalentLMS</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="relative mt-8 md:mt-0">
    <img src="/providers.png" alt="Arrow" className="w-[500px] h-auto align-center mx-auto" /> 
    </div>

      </div>
    </div>


       
        {!isPlanActive && (
        <div id="pricing" className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6 bg-custom-skyBlue">
          <div className="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
          <p className="text-center text-l text-gray-700 mt-12">PRICING</p>
            <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-gray-700 ">
              Designed for <span className="text-custom-cyan">business teams</span> like yours
            </h2>
            <p className="mb-5  text-gray-500 sm:text-xl ">
              Clear transparent pricing model to support all your needs. 
            </p>
          </div>
          <div className="flex justify-center max-w-[14rem] m-auto mb-8 lg:mb-16">
            <div className="relative flex w-full p-1 bg-white dark:bg-custom-dark rounded-full">
              <span className="absolute inset-0 m-1 pointer-events-none" aria-hidden="true">
                <span className={`absolute inset-0 w-1/2 bg-indigo-500 rounded-full shadow-sm shadow-indigo-950/10 transform transition-transform duration-150 ease-in-out ${isYearly ? 'translate-x-full' : ''}`}></span>
              </span>
              <button
                onClick={handleToggle}
                className={`relative flex-1 text-sm font-medium h-8 rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150 ease-in-out ${isYearly ? 'text-slate-500 dark:text-slate-400' : 'text-white'}`}
              >
                Yearly <span className="text-indigo-200">-10%</span>
              </button>
              <button
                onClick={handleToggle}
                className={`relative flex-1 text-sm font-medium h-8 rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150 ease-in-out ${isYearly ? 'text-white' : 'text-slate-500 dark:text-slate-400'}`}
              >
                Monthly
              </button>
            </div>
          </div>
          <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
            <div className="flex flex-col w-full md:w-80 p-6 mx-auto text-center text-gray-700 bg-white border border-gray-100 rounded-lg shadow xl:p-8">
              <h3 className="mb-4 text-2xl font-semibold">Starter</h3>
              <p className="font-light text-gray-500 sm:text-lg">
                Best option for single use &amp; for your next project.
              </p>
              <div className="flex items-baseline justify-center my-8">
                <span className="mr-2 text-5xl font-extrabold">{isYearly ? '$261' : '$29'}</span>
                <span className="text-gray-500">/month</span>
              </div>

              <ul className="mb-8 space-y-4 text-left">
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>Desktop & Mobile</span>
                </li>
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>50 AI credits included</span>
                </li>
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>
                    Team size: <span className="font-semibold">1 developer</span>
                  </span>
                </li>
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>
                    Premium support: <span className="font-semibold">6 months</span>
                  </span>
                </li>
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>
                    Marketplace: <span className="font-semibold">6 months</span>
                  </span>
                </li>
              </ul>
              <button
                onClick={handleNavigation}
                className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Get started
              </button>
            </div>

            <div className="flex flex-col w-full md:w-80 p-6 mx-auto text-center text-white bg-custom-lightBlue border rounded-lg shadow xl:p-8">
              <h3 className="mb-4 text-2xl font-semibold">Pro</h3>
              <p className="font-light text-white sm:text-lg">
                Relevant for professional users, AI Features &amp; premium support.
              </p>
              <div className="flex items-baseline justify-center my-8">
                <span className="mr-2 text-5xl font-extrabold">{isYearly ? '$711' : '$79'}</span>
                <span className="text-white">/month</span>
              </div>

              <ul className="mb-8 space-y-4 text-left">
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>LMS Integration and hosting</span>
                </li>
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>Unlimited AI credits</span>
                </li>
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>
                    Team size:
                    <span className="font-semibold"> 1 developer</span>
                  </span>
                </li>
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>
                    Premium support:
                    <span className="font-semibold"> 24 months</span>
                  </span>
                </li>
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>
                    Marketplace: <span className="font-semibold">24 months</span>
                  </span>
                </li>
              </ul>
              <button
                onClick={handleNavigation}
                className="text-white bg-custom-cyan hover:bg-custom-blue focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Get started
              </button>
            </div>

            <div className="flex flex-col w-full md:w-80 p-6 mx-auto text-center text-gray-700 bg-white border border-gray-100 rounded-lg shadow xl:p-8">
              <h3 className="mb-4 text-2xl font-semibold">Team</h3>
              <p className="font-light text-gray-500 sm:text-lg pb-6">
                Best for large teams and full integration.
              </p>
              <div className="flex items-baseline justify-center my-8">
                <span className="mr-2 text-5xl font-extrabold">{isYearly ? '$1161' : '$129'}</span>
                <span className="text-gray-500">/month</span>
              </div>

              <ul className="mb-8 space-y-4 text-left">
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>All Features</span>
                </li>
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>Unlimited AI credits</span>
                </li>
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>
                    Team size:
                    <span className="font-semibold"> 3 developers</span>
                  </span>
                </li>
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>
                    Premium support:
                    <span className="font-semibold"> Unlimited</span>
                  </span>
                </li>
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>
                    Marketplace: <span className="font-semibold">Unlimited</span>
                  </span>
                </li>
              </ul>
              <button
                onClick={handleNavigation}
                className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Get started
              </button>
            </div>
          </div>
        </div>
        )}
        
        <Newsletter />
        <Footer />
      </div>
    </>
  );
};

export default Content;