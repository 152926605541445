import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { publicApi } from '../../api/publicApi';

function CoursesPage() {
  const { organizationName } = useParams();
  const navigate = useNavigate();
  const [organizationData, setOrganizationData] = useState(null);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    // Check for login flag
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (!isLoggedIn) {
      // Redirect to login if no flag is found
      navigate(`/${organizationName}/login`);
      return;
    }

    const fetchOrganizationData = async () => {
      try {
        const orgListResponse = await publicApi.get('/api/organizations');
        const organizations = orgListResponse.data;
        const organization = organizations.find(org => org.attributes.Name === organizationName);

        if (organization) {
          const response = await publicApi.get(`/api/organizations/${organization.id}`);
          setOrganizationData(response.data.attributes);
        } else {
          console.error('Organization not found');
        }
      } catch (error) {
        console.error('Error fetching organization data:', error);
      }
    };

    const fetchCourses = async () => {
      try {
        const response = await publicApi.get(`/api/courses?organization=${organizationName}`);
        setCourses(response.data);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchOrganizationData();
    fetchCourses();
  }, [organizationName, navigate]);

  return (
    <div
      className="flex items-center min-h-screen p-6"
      style={{ backgroundColor: organizationData?.PrimaryColor || '#f0f0f0' }}
    >
      <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl">
        <div className="p-6">
          <h2 className="mb-4 text-xl font-semibold text-gray-700">Active Courses</h2>
          <ul>
            {courses.map(course => (
              <li key={course.id} className="mb-2">
                {course.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CoursesPage;
