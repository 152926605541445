import React, { useState, useEffect } from 'react';
import { api } from '../../api/api';
import { useAuth } from '../../context/AuthContext';



const SettingsLMS = () => {
  const { user } = useAuth();
  const [organization, setOrganization] = useState(null);
  const [newOrgName, setNewOrgName] = useState('');


  useEffect(() => {
    const fetchOrg = async () => {
      try {
        const response = await api.get(`/api/organizations?filters[OrgAdmin][$eq]=${user.email}`);
        const orgData = response.data[0];
        setOrganization({ id: orgData.id, ...orgData.attributes });
        console.log("Org-response", response);
      } catch (error) {
        console.error("Error fetching organizations", error);
      }
    };

    fetchOrg();
  }, [user.email]);

  // Update organization name in the database
  const updateOrgName = async () => {
    try {
        const response = await api.put(`/api/organizations/${organization.id}`, {
            data: {
                Name: newOrgName // Ensure the field name is correct
            }
        });
        console.log("Organization name updated", response.data);
    } catch (error) {
        console.error("Error updating organization name", error);
    }
};


  const loginPortalUrl = `${process.env.FRONT_END_URL}/${organization ? organization.Name : ''}`;

  return (
    <div className="bg-white p-6">
      <main className="flex-1">
        <h1 className="text-3xl font-bold mb-6">Settings</h1>
        {organization && (
          <div className="mb-6 p-4 border rounded-lg shadow-sm">
            <h2 className="text-2xl font-semibold mb-2">Organization Details</h2>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <strong>Name:</strong>
                <input
                  type="text"
                  value={newOrgName || organization.Name}
                  onChange={(e) => setNewOrgName(e.target.value)}
                  className="border p-2 w-full"
                />
                <button
                  onClick={updateOrgName}
                  className="bg-blue-500 text-white p-2 mt-2 rounded"
                >
                  Save
                </button>
              </div>
              <p><strong>Primary Color:</strong> {organization.PrimaryColor}</p>
              <p><strong>Secondary Color:</strong> {organization.SecondaryColor}</p>
              <p><strong>Admin Email:</strong> {organization.OrgAdmin}</p>
              <p>{organization.LoginPortal}</p>
            </div>
            <img src={organization.Logo} alt="Organization Logo" className="h-20 w-20 mt-4" />
          </div>
          
        )}
        <p className="mb-4">Your Login Portal</p>
        
        <div className="flex items-center mb-6">
          <input
            type="text"
            value={loginPortalUrl}
            readOnly
            className="border p-2 mr-2 flex-grow"
          />
          <button
            onClick={() => navigator.clipboard.writeText(loginPortalUrl)}
            className="bg-blue-500 text-white p-2 rounded"
          >
            Click to Copy
          </button>
        </div>
        {/* User Portal Style Section */}
        <section className="mt-8">
          <h2 className="text-xl font-semibold mb-4">User Portal Style</h2>
          <div className="border rounded-lg overflow-hidden shadow-sm">
            <div className="flex items-center justify-between p-4" style={{ backgroundColor: '#f0f0f0' }}>
              <input
                type="color"
                onChange={(e) => {
                  const topBar = document.querySelector('.top-bar');
                  if (topBar) topBar.style.backgroundColor = e.target.value;
                }}
                className="mr-2"
              />
              <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                      const topBar = document.querySelector('.top-bar');
                      if (topBar) topBar.style.backgroundImage = `url(${event.target.result})`;
                    };
                    reader.readAsDataURL(file);
                  }
                }}
              />
            </div>
            <div className="top-bar h-8" style={{ backgroundColor: '#f0f0f0' }}></div>
            <div className="p-4">
              <p>This styles will apply to the user login screen and user portal.</p>
            </div>
          </div>
        </section>
        {/* Login Provider Section */}
        <section className="mt-8">
          <h2 className="text-xl font-semibold mb-4">Login Provider</h2>
          <div className="border rounded-lg p-4 shadow-sm">
            <label className="block mb-2">Select your login provider:</label>
            <select className="border p-2 w-full">
              <option value="email">Email</option>
              <option value="google">Google</option>
              <option value="azure">Azure</option>
              <option value="sso">SSO</option>
            </select>
          </div>
        </section>
      </main>
    </div>
  );
};


export default SettingsLMS; 
