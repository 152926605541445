import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import { api } from '../../api/api';
import moment from 'moment';
import { useAuth } from '../../context/AuthContext';
import addPublicAccessToFolder from './AddPublicPolicyS3';
import { SquareDashed, PerspectiveView, Settings } from 'iconoir-react'; // Import icons
import AnalyticsSection from './AnalyticsSection';
import SettingsLMS from './SettingsLMS';

const DeployLMS = () => {
  const { user } = useAuth();
  const [deployedProjects, setDeployedProjects] = useState([]);
  const S3_BUCKET_NAME = 'slidesdeck' // Import S3_BUCKET_NAME from environment variables
  const [activeTab, setActiveTab] = useState('overview');
  const [setInfoMessage] = useState('');

  // Function to handle Share Link button click
  const handleShareLinkClick = async (projectId) => {
    try {
      await addPublicAccessToFolder(S3_BUCKET_NAME, user.user_id, projectId);
      console.log('Bucket configured for static hosting successfully');

      // Construct the public URL with index.html
      const publicUrl = `https://${S3_BUCKET_NAME}.s3.amazonaws.com/${user.user_id}/${projectId}/index.html`;
      console.log('Public URL:', publicUrl);

      // Display the URL in the UI
      setInfoMessage(`Public URL: ${publicUrl}`);
    } catch (err) {
      console.error('Error configuring bucket:', err);
    }
  };

  const deleteProject = async (projectId) => {
    // Optimistically update the UI
    setDeployedProjects((prev) => prev.filter(project => project.project_id !== projectId));

    try {
      const response = await api.put(`/api/projects/${projectId}`, {
        data: {
          deployed: false,
          deployedAt: null
        }
      });
      console.log("response", response);
      if (response.status === 200) {
        console.log('Project undeployed successfully!');
      }
    } catch (error) {
      console.error('Error undeploying project:', error);
      console.log('Undeployment failed. Please try again.');
      // Revert the UI change if the API call fails
      setDeployedProjects((prev) => [...prev, { project_id: projectId }]);
    }
  };

  useEffect(() => {
    const fetchDeployedProjects = async () => {
      try {
        const response = await api.get(`/api/projects?filters[user_id][$eq]=${user.user_id}&filters[deployed][$eq]=true`);
        setDeployedProjects(response.data);
      } catch (error) {
        console.error('Error fetching deployed projects:', error);
      }
    };

    fetchDeployedProjects();
  }, [user.user_id]); // Add user.user_id to the dependency array


  

  return (
    <div className="flex h-screen bg-custom-skyBlue">
      <Sidebar />

      <div className="flex-1 flex flex-col">
        <TopBar />
        <div className="flex-1 flex bg-custom-skyBlue p-4 overflow-auto">
          <aside className="w-1/4 p-4">
            <nav>
              <ul>
                <li>
                  <button
                    className={`w-full text-left p-2 flex items-center space-x-2 ${activeTab === 'overview' ? 'bg-gray-200' : ''}`}
                    onClick={() => setActiveTab('overview')}
                  >
                    <SquareDashed className="w-6 h-6" />
                    <span className="text-sm mb-1 font-bold">Overview</span>
                  </button>
                </li>
                <li>
                  <button
                    className={`w-full text-left p-2 flex items-center space-x-2 ${activeTab === 'analytics' ? 'bg-gray-200' : ''}`}
                    onClick={() => setActiveTab('analytics')}
                  >
                    <PerspectiveView className="w-6 h-6" />
                    <span className="text-sm mb-1 font-bold">Analytics</span>
                  </button>
                </li>
                <li>
                  <button
                    className={`w-full text-left p-2 flex items-center space-x-2 ${activeTab === 'settings' ? 'bg-gray-200' : ''}`}
                    onClick={() => setActiveTab('settings')}
                  >
                    <Settings className="w-6 h-6" />
                    <span className="text-sm mb-1 font-bold">LMS Settings</span>
                  </button>
                  {/* Add more buttons as needed */}
                </li>
              </ul>
            </nav>
          </aside>
          <main className="flex-1 bg-custom-skyBlue p-4 overflow-auto">
            {/* Content based on activeTab */}
            {activeTab === 'overview' && (
              <div>
                {/* Existing content for the Overview section */}
                <div className="bg-white p-6 rounded shadow">
                  <h1 className="text-2xl font-bold mb-4">Deployed Projects Dashboard</h1>
                  {deployedProjects.length > 0 ? (
                    <div className="space-y-6">
                      {deployedProjects.map((project, index) => (
                        <React.Fragment key={project.project_id}>
                          <div className="bg-white p-4 flex items-top">
                            <div className="flex items-center w-1/2">
                              <a href={`https://${S3_BUCKET_NAME}.s3.amazonaws.com/${user.user_id}/${project.project_id}/index.html`} target="_blank" rel="noopener noreferrer">
                                <img
                                  src={`https://backend.ailms.co${project.project_image?.url}`}
                                  alt={project.name}
                                  className="w-32 h-32 object-cover rounded"
                                />
                              </a>
                              <div className="items-top ml-4">
                                <h3 className="text-lg font-semibold">{project.name}</h3>
                                <p>Deployed on: {moment(project.deployedAt).format('MMM Do YYYY')}</p>
                                <p>Status: Deployed</p>
                                <p>Public URL: <a href={`https://${S3_BUCKET_NAME}.s3.amazonaws.com/${user.user_id}/${project.project_id}/index.html`} target="_blank" rel="noopener noreferrer">{`https://${S3_BUCKET_NAME}.s3.amazonaws.com/${user.user_id}/${project.project_id}/index.html`}</a></p>
                              </div>
                            </div>
                            
                            <div className="w-1/4 items-right font-sm">
                              <button
                                className="bg-blue-500 text-white px-4 py-2 rounded mb-2 mr-2"
                                onClick={() => handleShareLinkClick(project.project_id)}
                              >
                                Share Link
                              </button>
                              <button className="bg-blue-500 text-white px-4 py-2 rounded mb-2 mr-2">Embed Code</button>
                              <button
                                className="bg-red-500 text-white px-4 py-2 rounded mb-2 mr-2"
                                onClick={() => deleteProject(project.project_id)}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                          {index < deployedProjects.length - 1 && <hr className="my-4" />}
                        </React.Fragment>
                      ))}
                    </div>
                  ) : (
                    <p>No projects have been deployed yet.</p>
                  )}
                </div>
              </div>
            )}
            {activeTab === 'analytics' && <AnalyticsSection/>}
            {activeTab === 'settings' && <SettingsLMS/>}
            {/* Add more content components as needed */}
          </main>
        </div>
      </div>
    </div>
  );
};

export default DeployLMS;
