import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquare, faEyeDropper} from "@fortawesome/free-solid-svg-icons";
import {SketchPicker} from "react-color";
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import BorderFill from "./BorderFill";
import {FillIcon} from "../../../utils/iconHelper";
import { convertHexToRGB } from "../../../utils/helpers";

const ShapeFill = () => {

    const {activeSlide} = useSelector(state => state.slideList);
    const {activeObject } = useSelector(state => state.fabricCanvas);
    const colorPickerRef = useRef(null);
    const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
    const [fill, setFill] = useState("rgb(0,0,0)");

    const toggleColorPicker = () => {
        setIsColorPickerOpen((prevIsColorPickerOpen) => !prevIsColorPickerOpen);
    };

    const handleClickOutside = (event) => {
        if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
            setIsColorPickerOpen(false);
        }
    };

    useEffect(() => {
        // Add event listener on mount
        document.addEventListener('mousedown', handleClickOutside);

        // Remove event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []); // Empty dependency array means this effect runs only once, on mount


    useEffect(() => {
        if (activeObject) {
            const {fill} = activeObject;
            setFill(fill);
        }
    }, [activeObject]);


    // Handler for shape color selection
    const handleShapeColorChange = (color) => {
        const {canvas} = activeSlide;
        let newValue;

        // If the color is selected form eye drop api it will have sRGBHex value else rgb
        if (color.sRGBHex) {
            newValue = convertHexToRGB(color.sRGBHex);
        } else {
            newValue = color.rgb;
        }

        setFill(`rgba(${newValue.r}, ${newValue.g}, ${newValue.b}, ${newValue.a})`);
        if(activeObject.type === 'group'){
            activeObject.getObjects().forEach(ob=>{
                // ob.set({fill: `rgba(${newValue.r}, ${newValue.g}, ${newValue.b}, ${newValue.a})`,})
                ob.set({stroke: `rgba(${newValue.r}, ${newValue.g}, ${newValue.b}, ${newValue.a})`,})
            })
        }
        else{
            activeObject.set({
                fill: `rgba(${newValue.r}, ${newValue.g}, ${newValue.b}, ${newValue.a})`,
            });
        }

        canvas.renderAll();
    }

    const handleEyeDropperClick = () => {
        // eslint-disable-next-line no-undef
        const eyeDropper = new EyeDropper();
        eyeDropper.open().then((color) => {
            handleShapeColorChange(color);
        });
    };

    return (
        <>
            <label htmlFor="fontSelector" className="mb-2 block text-xxs font-medium text-gray-600">
                Colors</label>
            <div className="mt-2 flex items-start justify-between mb-6 w-full flex-col gap-6">
                {activeObject.type !== 'line' &&
                <div className="flex items-center bg-white w-fit">
                    <div ref={colorPickerRef} className={`relative flex items-center ${activeObject.type !== 'path' && activeObject.type !== 'group' ? 'w-fit' : 'w-fit'}`}>
                        <button
                            className={`flex w-full h-10 cursor-pointer items-center justify-center rounded-md space-x-4 border p-2 text-xxs px-4 ${isColorPickerOpen ? 'bg-gray-100' : 'bg-white'} ${activeObject.type !== 'path' && activeObject.type !== 'group' ? 'rounded-r-none border border-r-0' : 'border'}`}
                            onClick={toggleColorPicker}
                        >
                            <FillIcon className="size-3 text-gray-600"/>
                            <FontAwesomeIcon icon={faSquare} color={fill} className="size-3 !ml-2"/>
                        </button>
                        {isColorPickerOpen && (
                            <div className="absolute top-full mt-2 z-50">
                                <SketchPicker color={fill} onChange={handleShapeColorChange}/>
                            </div>
                        )}
                    </div>
                    <div className="h-10 flex items-center border-y border rounded-r-md px-3" onClick={handleEyeDropperClick}>
                        <FontAwesomeIcon icon={faEyeDropper} className="size-3" />
                    </div>
                    </div>
                }
                {activeObject.type !== 'path' && activeObject.type !== 'group' && <BorderFill/>}
            </div>
        </>
    )
}
export default ShapeFill;