import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Topbar from "../../modules/LayoutModule/Content/Topbar/Topbar";
import { CommentSection } from 'react-comments-section';
import 'react-comments-section/dist/index.css';
import Footer from "../../modules/LayoutModule/Content/Footer/Footer";
import { api } from "../../api/api";

const SingleProject = () => {
  const { project_id, project_name } = useParams(); // Get project ID from URL
  const [project, setProject] = useState(null); // State to store project details
  const [activeTab, setActiveTab] = useState('description'); // State for active tab

  useEffect(() => {
    api.get(`/api/projects/${project_id}`)
      .then(response => {
        setProject(response.data.data);
      })
      .catch(error => {
        console.error("Error fetching project details:", error);
      });
  }, [project_id]);
  const sampleComments = [
    {
      userId: '01a',
      comId: '012',
      fullName: 'Riya Negi',
      avatarUrl: 'https://ui-avatars.com/api/name=Riya&background=random',
      userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
      text: 'Hey, Loved your blog! ',
      replies: []
    },
    {
      userId: '02b',
      comId: '017',
      fullName: 'Lily',
      userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
      text: 'I have a doubt about the 4th point🤔',
      avatarUrl: 'https://ui-avatars.com/api/name=Lily&background=random',
      replies: []
    }
  ];

  return (
    <div>
      <Topbar /> {/* Add Topbar component */}
       {/* Jumbotron with Search Bar */}
       <div className="relative text-white text-center w-full"> {/* Removed py-12 */}
          <img src="/header_image.png" alt="Header" className="absolute inset-0 w-full h-full object-cover" /> {/* Full width image */}
          <img src="/component4.png" alt="Component 4" className="absolute bottom-10 left-0 w-24 h-4" /> 
          <img src="/component4.png" alt="Component 4" className="absolute top-10 opacity-50 right-0 w-24 h-4" /> {/* Added image in the bottom left corner */}
          <div className="relative z-10 py-12"> {/* Added py-12 to inner div */}
            <h1 className="text-4xl font-bold mb-4">Marketplace</h1>
            <p className="mb-6">Explore our wide range of projects and assets available for purchase.</p>
            
          </div>
        </div>

      {/* Breadcrumb navigation */}
      <div className="p-4">
        <nav className="text-sm text-gray-500">
          <a href="/" className="hover:underline">Home</a> &gt; 
          <a href="/Marketplace" className="hover:underline">Marketplace</a> &gt;
          <span className="text-gray-700">{project_name?.name || project_name}</span>
        </nav>
      </div>

      {project && (
        <div className="p-4">
          {/* Project Image and Gallery */}
          <div className="flex flex-col md:flex-row-reverse gap-6">
          <div className="w-full md:w-2/3">
            <img 
              src={`https://backend.ailms.co${project.attributes.project_image.data.attributes.formats.large?.url || project.attributes.project_image.data.attributes.url}`}
              alt={project.attributes.name} 
              className="w-full h-96 object-cover rounded-lg" 
            />
            <div className="mt-4 flex gap-2 overflow-x-auto">
              {project.attributes.gallery_images && project.attributes.gallery_images.data.map((img, index) => (
                <img 
                  key={index} 
                  src={`https://backend.ailms.co${img.attributes.formats.thumbnail.url}`} 
                  alt={project.attributes.name || "Project image"}
                  className="h-20 w-20 object-cover rounded-lg cursor-pointer"
                />
              ))}
              {project.attributes.slides.data.slice(0, 3).map((slide, index) => (
                 slide.attributes.thumbnail ? (
                <img 
                  key={index} 
                  src={slide.attributes.thumbnail} 
                  alt={`Slide ${index + 1}`}
                  className="h-20 w-20 object-cover rounded-lg cursor-pointer"
                />
              ) : (
                  <div key={index} className="h-20 w-20 bg-gray-200 rounded-lg"></div>
                )
              ))}
              {project.attributes.slides.data.length < 3 && 
                Array.from({ length: 3 - project.attributes.slides.data.length }).map((_, index) => (
                  <div key={`placeholder-${index}`} className="h-20 w-20 bg-gray-200 rounded-lg"></div>
                ))
              }
            </div>
          </div>

          <div className="w-full md:w-1/3">
            {/* Project Info */}
            <h1 className="text-3xl font-bold mb-4">{project.attributes.name}</h1>
            <p className="text-gray-700 mb-2">Category: {project.attributes.category}</p>
            <p className="text-gray-700 mb-2">Price: ${project.attributes.price}</p>

            {/* Tags */}
            <div className="flex flex-wrap gap-2 mb-4">
              {project.attributes.tags.data && project.attributes.tags.data.length > 0 ? project.attributes.tags.data.map(tag => (
                <span key={tag.id} className="bg-blue-200 hover:bg-blue-300 py-1 px-2 rounded-lg text-xs">
                  {tag.attributes.tagName}
                </span>
              )) : "No tags available"}
            </div>

            {/* Share Button */}
            <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg">
              Share Project
            </button>
          </div>
        </div>
          {/* Tabs for Description and Comments */}
          <div className="mt-8">
                <div className="tabs flex">
                  <button 
                    className={`mr-4 text-lg ${activeTab === 'description' ? 'font-bold' : ''}`} 
                    onClick={() => setActiveTab('description')}
                  >
                    Description
                  </button>
                  <button 
                    className={`text-lg ${activeTab === 'comments' ? 'font-bold' : ''}`} 
                    onClick={() => setActiveTab('comments')}
                  >
                    Comments
                  </button>
                </div>

                <div className="tab-content mt-4">
                  {activeTab === 'description' && (
                    <p className="text-gray-700">
                      {typeof project.attributes.description === 'string' ? project.attributes.description : "Sample description: This is a placeholder description for the project. Detailed information about the project will be displayed here."}
                    </p>
                  )}
                  
                  {activeTab === 'comments' && (
                    <CommentSection
                        currentUser={{
                          currentUserId: '01a',
                          currentUserImg: 'https://ui-avatars.com/api/name=Riya&background=random',
                          currentUserProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
                          currentUserFullName: 'Riya Negi'
                        }}
                        logIn={{
                          loginLink: 'http://localhost:3001/',
                          signupLink: 'http://localhost:3001/'
                        }}
                        commentData={sampleComments}
                        onSubmitAction={(data) => console.log('check submit, ', data)}
                        currentData={(data) => {
                          console.log('current data', data)
                        }}
                    />
                  )}
                </div>
              </div>

            
          </div>
      
      )}
      <Footer />
    </div>
  );
};

export default SingleProject;
