import React, { useEffect } from 'react';
import Sidebar from './Sidebar';
import TopBar from './TopBar';

const ProjectHistory = ({ historyData = [] }) => {
  useEffect(() => {
    console.log('History Data:', historyData); // Log the history data
  }, [historyData]);

  return (
    <div className="flex h-screen bg-custom-skyBlue">
      <Sidebar />

      <div className="flex-1 flex flex-col">
        <TopBar />
        <main className="flex-1 bg-custom-skyBlue p-4 overflow-auto">
          <div className="bg-white p-6 rounded shadow">
            <h3 className="text-lg font-semibold mb-2">Project History</h3>
            <div className="relative overflow-x-auto">
              {historyData.length === 0 ? (
                <p className="text-center text-gray-700">No history available.</p>
              ) : (
                <table className="w-full text-sm border border-gray-300 text-left text-white dark:text-white">
                  <thead className="text-xs text-white uppercase bg-custom-blue">
                    <tr>
                      <th scope="col" className="px-6 py-3">Version</th>
                      <th scope="col" className="px-6 py-3">Slide Version</th>
                      <th scope="col" className="px-6 py-3">Date</th>
                      <th scope="col" className="px-6 py-3">Changes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyData.map((entry, index) => {
                      console.log('Rendering entry:', entry); // Log each entry
                      return (
                        <tr
                          key={entry.slideVersion}
                          className={`bg-white border-b text-black ${index % 2 === 0 ? 'bg-light-blue-100' : ''}`}
                        >
                          <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap overflow-hidden overflow-ellipsis dark:text-black max-w-xs">
                            {entry.version}
                          </td>
                          <td className="px-6 py-4">{entry.slideVersion}</td>
                          <td className="px-6 py-4">{entry.timestamp}</td>
                          <td className="px-6 py-4">{entry.changes || 'N/A'}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ProjectHistory;