import React, { useEffect, useState } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import RightPanel from '../../components/RightPanel/RightPanel';
import RevealSlide from '../../components/RevealSlide/RevealSlide';
import LinkViewPopup from '../../components/LinkViewPopup';
import JumpToSlidePopup from '../../components/JumpToSlidePopup';
import Header from '../../components/Header/Header';
import { useSelector } from 'react-redux';
import ContextMenu from '../../components/ContextMenu/ContextMenu';
import VideoControl from '../../components/VideoControls/VideoControls';
import Sidebar from '../../components/Sidebar/Sidebar';
import {Notes, NavArrowDown} from 'iconoir-react'

library.add(fas);

const EditorContainer = () => {
  const { activeObject, isContext, videoControl } = useSelector(
    (state) => state.fabricCanvas
  );
  const [activeComponent, setActiveComponent] = useState('text');
  const { show: showCropper } = useSelector((state) => state.cropper);
  const [zoomLevel, setZoomLevel] = useState(60);
 

  const handleZoomChange = (newZoomLevel) => {
    setZoomLevel(newZoomLevel);
  };

  useEffect(() => {
    if (activeObject && !showCropper) {
      switch (activeObject.type) {
        case 'text':
          setActiveComponent('text');
          break;
        case 'image':
          // case 'rect':
          if (activeObject.name === 'video') setActiveComponent('video');
          else setActiveComponent('image');
          break;
        case 'polygon':
        case 'circle':
        case 'path':
        case 'group':
        case 'line':
        case 'svg':
          setActiveComponent('shape');
          break;
        case 'audio':
          setActiveComponent('audio');
          break;
        case 'components':
          setActiveComponent('components');
          break;
        case 'docs':
          setActiveComponent('docs');
          break;
        case 'button':
        case 'textInput':
        case 'card':
          setActiveComponent('components');
          break;
        case 'quiz':
          setActiveComponent('quiz');
          break;
        case "avatar":
          setActiveComponent('avatar');
          break;
        default:
          setActiveComponent('text');
      }
    } else {
      setActiveComponent('text');
    }
  }, [activeObject, showCropper]);

  // Function to handle changes in activeComponent
  const handleActiveComponentChange = (component) => {
    setActiveComponent(component);
  };

  return (
    <>
      <Header />
      <div
        className="flex h-screen"
        style={{ maxHeight: "calc(100vh - 80px)" }}
      >
        {isContext && <ContextMenu />}
        {videoControl && <VideoControl />}
        <LinkViewPopup />
        <JumpToSlidePopup />
        <Sidebar onActiveComponentChange={handleActiveComponentChange} />
        <RightPanel activeComponent={activeComponent} /> {/* Move RightPanel here */}

        <RevealSlide zoomLevel={zoomLevel} onZoomChange={handleZoomChange} />
      </div>
      <div className="h-35 flex bg-[#e1e1e1] items-start h-screen relative">
        <div className="flex justify-between w-full">
          <div className="flex items-center z-10 relative left-96 mb-1">
            <Notes className="w-3 h-3 text-gray-500 text-xxs font-bold cursor-pointer" />
            <p className="text-xxs font-gray-500 ml-1 cursor-pointer">Notes</p>
            <NavArrowDown className="w-3 h-3 text-gray-500 text-xxs font-bold cursor-pointer ml-1" />

          </div>
          <div className="relative z-10 block">
            <div className="flex justify-end items-center select-none mb-1">

              <div className="mx-2 text-xxs">
                {zoomLevel}%
              </div>
              <NavArrowDown className="w-4 h-4 font-bold cursor-pointer" />

              <div
                className="w-5 h-5 flex items-center justify-center text-black text-xs font-bold cursor-pointer"
                onClick={() => handleZoomChange(zoomLevel - 2)}>
                <span className="select-none w-5 h-5 flex justify-center items-center">-</span>
              </div>
              <input
                id="small-range"
                type="range"
                min="80"
                max="200"
                step="2"
                value={zoomLevel}
                onChange={(e) => handleZoomChange(parseInt(e.target.value))}
                className="w-full h-1 mb-6 bg-gray-200 rounded-lg appearance-none cursor-pointer range-sm custom-slider"
              />
              <div
                className="w-5 h-5 flex items-center justify-center text-black text-xs font-bold cursor-pointer mx-1"
                onClick={() => handleZoomChange(zoomLevel + 2)}>
                <span className="select-none w-5 h-5 flex justify-center items-center">+</span>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) ;
}

export default EditorContainer;