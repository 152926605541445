import React from 'react';
import {
  TwitterIcon,
  YoutubeIcon,
  InstagramIcon,
  FacebookIcon,
} from '../../../../utils/iconHelper';

const Footer = () => {
  return (
    <div className="bg-custom-dark text-white">
      <div className="max-w-screen-lg py-10 px-4 sm:px-6 flex flex-col sm:flex-row justify-between mx-auto">
        <div className="p-5 sm:w-7/12 text-left">
          <h1 className="font-bold text-xxl text-white mb-4">ailms.co</h1>
          <p className="text-gray-400 text-sm mb-10">
            Empowering Corporate Learning Everywhere with AI and Innovation: A Complete SCORM-Compatible, Browser-Based, Mobile-Friendly eLearning Solution for Every Company.
          </p>
        </div>
        <div className="p-5 sm:w-2/12">
          <div className="text-sm text-teal-400 font-bold">Menu</div>
          <ul>
            <li className="my-2">
              <a className="hover:text-teal-400" href="/">
                Home
              </a>
            </li>
            <li className="my-2">
              <a className="hover:text-teal-400" href="/">
                Services
              </a>
            </li>
            <li className="my-2">
              <a className="hover:text-teal-400" href="/">
                Products
              </a>
            </li>
            <li className="my-2">
              <a className="hover:text-teal-400" href="/">
                Pricing
              </a>
            </li>
          </ul>
        </div>
        <div className="p-5 sm:w-3/12">
          <div className="text-sm text-teal-400 font-bold">
            Contact Us
          </div>
          <ul>
            <li className="my-2">
              <a className="hover:text-teal-400" href="/">
                Valencia, Spain
              </a>
            </li>
            <li className="my-2">
              <a className="hover:text-teal-400" href="/">
                contact@ailms.co
              </a>
            </li>
          </ul>
        </div>
        <div className="p-5 sm:w-3/12">
          <div className="text-sm text-teal-400 font-bold">
            Follow Us
          </div>
          <div className="flex mt-2">
            <a href="/" className="w-6 mx-1">
              <FacebookIcon />
            </a>
            <a href="/" className="w-6 mx-1">
              <InstagramIcon />
            </a>
            <a href="/" className="w-6 mx-1">
              <TwitterIcon />
            </a>
            <a href="/" className="w-6 mx-1">
              <YoutubeIcon />
            </a>
          </div>
        </div>
      </div>
      <div className="flex py-5 m-auto text-gray-400 text-sm flex-col items-center border-t border-gray-700 max-w-screen-xl">
        <div className="flex flex-col sm:flex-row justify-between w-full max-w-screen-lg">
          <a href="/" className="hover:text-teal-400 mb-2 sm:mb-0">
            Privacy Policy
          </a>
          <div>© Copyright 2024. All Rights Reserved.</div>
          <a href="/" className="hover:text-teal-400 mb-2 sm:mb-0">
            Terms & Conditions
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;