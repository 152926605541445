// src/components/TopBar.jsx
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth , useCheckSubscription} from "../../context/AuthContext";
import TextSearch from '../../components/TextSearch/TextSearch';

const TopBar = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const checkSubscription = useCheckSubscription();
  const [subscriptionData, setSubscriptionData] = useState([]); // Ensure it's initialized as an array

  const handleNavigate = (path) => {
    navigate(path);
  };
  
  
  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const response = await checkSubscription(user);
        const data = response?.data || []; // Ensure data is an array
        console.log("data", data);
        setSubscriptionData(data);
      } catch (error) {
        console.error("Failed to fetch subscription data:", error);
        setSubscriptionData([]); // Fallback to an empty array on error
      }
    };

    fetchSubscriptionData();
  }, [checkSubscription, user]);

  const planTitle = subscriptionData.length > 0 
    ? subscriptionData[0]?.product?.title || "No Plan Name"
    : "PRO PLAN";

  return (
    <header className="flex justify-between items-center p-4 bg-white">
      <p className="text-2xl pr-12 text-custom-topBlue font-extrabold">ailms.co Dashboard</p>
      <div className="flex-1 flex justify-center w-1/3">
        <TextSearch placeholder="Search for anything..." />
      </div>
      <div className="flex items-center">
        <div className="flex items-center ml-12 cursor-pointer"
        onClick={() => handleNavigate("/profile")}>
          <img
            src={user?.avatar ? `https://backend.ailms.co${user?.avatar}` : <div className="w-8 h-8 rounded-full bg-gray-300"></div>}
            alt="Profile"
            className="w-8 h-8 rounded-full border-[1px] border-grey"
          />
          <div className="flex flex-col ml-2">
            <span className="text-sm mb-[2px] font-bold">{user?.username}</span>
            <span className="text-xxs text-grey">{planTitle}</span> 
          </div>
        </div>
      </div>
    </header>
  );
};

export default TopBar;