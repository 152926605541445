import React, {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faSquare, faEyeDropper } from "@fortawesome/free-solid-svg-icons";
import {SketchPicker} from "react-color";
import {useSelector} from "react-redux";
import {isShape} from "../../../utils/helpers";
import {BorderFillIcon} from "../../../utils/iconHelper";
import { convertHexToRGB } from "../../../utils/helpers";

const BorderFill = () => {

    const {activeObject, canvas} = useSelector(state => state.fabricCanvas);
    // const [borderStyle, setBorderStyle] = useState('None');
    const [borderWidth, setBorderWidth] = useState(0);
    const [borderFill, setBorderFill] = useState("rgb(0,0,0)")
    const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
    const colorPickerRef = useRef(null);

    const toggleColorPicker = () => {
        setIsColorPickerOpen((prevIsColorPickerOpen) => !prevIsColorPickerOpen);
    };

    const handleClickOutside = (event) => {
        if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
            setIsColorPickerOpen(false);
        }
    };

    useEffect(() => {
        if(activeObject) {
            const {borderWidth, borderFill, stroke, strokeWidth} = activeObject;
            // if(!isShape(activeObject))setBorderStyle(borderStyle);
            setBorderWidth(isShape(activeObject)?strokeWidth:borderWidth);
            setBorderFill(isShape(activeObject)?stroke:borderFill);
        }
    }, [activeObject])

    useEffect(() => {
        // Add event listener on mount
        document.addEventListener('mousedown', handleClickOutside);

        // Remove event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []); // Empty dependency array means this effect runs only once, on mount

    const handleBorderWidthChange = (event) => {
        const newValue = parseInt(event.target.value);
        if(isShape(activeObject)){
            activeObject.set({
                strokeWidth: newValue
            })
        }
        else{
            activeObject.set({
                borderWidth: newValue
            })
        }

        canvas.renderAll();
        setBorderWidth(newValue);
    }

    const handleBorderFillChange = (color) => {
        let newValue;

        // If the color is selected form eye drop api it will have sRGBHex value else rgb
        if (color.sRGBHex) {
            newValue = convertHexToRGB(color.sRGBHex);
        } else {
            newValue = color.rgb;
        }

        if(isShape(activeObject)){
            activeObject.set({
                stroke: `rgba(${newValue.r}, ${newValue.g}, ${newValue.b}, ${newValue.a})`,
            });
        }
        else{
            activeObject.set({
                borderFill: `rgba(${newValue.r}, ${newValue.g}, ${newValue.b}, ${newValue.a})`,
            });
        }
        setBorderFill(`rgba(${newValue.r}, ${newValue.g}, ${newValue.b}, ${newValue.a})`);
        canvas.renderAll();
    }

    const handleEyeDropperClick = () => {
        // eslint-disable-next-line no-undef
        const eyeDropper = new EyeDropper();
        eyeDropper.open().then((color) => {
            handleBorderFillChange(color);
        });
    };

    return (
            <div className="flex items-center w-full">
                <div ref={colorPickerRef} className="relative flex items-center">
                    <button
                        className={`flex h-10 px-4 cursor-pointer rounded-l-md items-center justify-center space-x-4 border px-2 text-xxs w-fit ${isColorPickerOpen ? 'bg-gray-100' : 'bg-white'}`}
                        onClick={toggleColorPicker}
                    >
                        <BorderFillIcon className="size-3 text-gray-600"/>
                        <FontAwesomeIcon icon={faSquare} color={borderFill} className="!ml-3 size-3"/>
                    </button>
                    {isColorPickerOpen && (
                            <div className="absolute top-full mt-2 z-50">
                                <SketchPicker color={borderFill} onChange={handleBorderFillChange}/>
                            </div>
                        )}
                </div>
                <div className="h-10 flex items-center border-y border-r px-3" onClick={handleEyeDropperClick}>
                    <FontAwesomeIcon icon={faEyeDropper} className="size-3" />
                </div>
                <input
                    className="flex h-10 rounded-md rounded-l-none border-l-0 items-center justify-center border bg-white p-2 text-xxs text-gray-600 transition-colors duration-200"
                    type="number"
                    onChange={handleBorderWidthChange}
                    value={borderWidth}
                    min={0}
                    max={15}
                />
            </div>

    )
}
export default BorderFill;