import React, { useEffect, useRef } from 'react';
import { User, Wallet, ShoppingBag } from 'iconoir-react';
import * as d3 from 'd3';

const LineGraph = ({ data }) => {
  const d3Container = useRef(null);

  useEffect(() => {
    if (d3Container.current) {
      const margin = { top: 20, right: 10, bottom: 30, left: 40 };
      const width = 700 - margin.left - margin.right;
      const height = 300 - margin.top - margin.bottom;

      const svg = d3.select(d3Container.current)
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      const x = d3.scaleBand()
        .domain(data.map(d => d.month))
        .range([0, width])
        .padding(0.1);

      const y = d3.scaleLinear()
        .domain([0, d3.max(data, d => d.users)])
        .nice()
        .range([height, 0]);

      svg.append('g')
        .attr('transform', `translate(0,${height})`)
        .call(d3.axisBottom(x));

      svg.append('g')
        .call(d3.axisLeft(y));

      svg.append('path')
        .datum(data)
        .attr('fill', 'none')
        .attr('stroke', 'steelblue')
        .attr('stroke-width', 1.5)
        .attr('d', d3.line()
          .x(d => x(d.month) + x.bandwidth() / 2)
          .y(d => y(d.users))
        );
    }
  }, [data]);

  return <svg ref={d3Container}></svg>;
};

const BarGraph = ({ data }) => {
  const d3Container = useRef(null);

  useEffect(() => {
    if (d3Container.current) {
      const width = 300;
      const height = 200;

      const svg = d3.select(d3Container.current)
        .attr('width', width)
        .attr('height', height + 50)
        .append('g');

      const x = d3.scaleBand()
        .domain(data.map(d => d.category))
        .range([0, width])
        .padding(0.1);

      const y = d3.scaleLinear()
        .domain([0, d3.max(data, d => d.value)])
        .range([height, 0]);

      svg.append('g')
        .attr('transform', `translate(0,${height})`)
        .call(d3.axisBottom(x));

      svg.append('g')
        .call(d3.axisLeft(y));

      svg.selectAll('.bar')
        .data(data)
        .enter()
        .append('rect')
        .attr('class', 'bar')
        .attr('x', d => x(d.category))
        .attr('y', d => y(d.value))
        .attr('width', x.bandwidth())
        .attr('height', d => height - y(d.value))
        .attr('fill', 'steelblue');
    }
  }, [data]);

  return <svg ref={d3Container}></svg>;
};

const PieChart = ({ data }) => {
  const d3Container = useRef(null);

  useEffect(() => {
    if (d3Container.current) {
      const radius = 100;

      const svg = d3.select(d3Container.current)
        .attr('width', radius * 2)
        .attr('height', radius * 2)
        .append('g')
        .attr('transform', `translate(${radius},${radius})`);

      const pie = d3.pie();
      const arc = d3.arc()
        .innerRadius(0)
        .outerRadius(radius);

      svg.selectAll('path')
        .data(pie(data))
        .enter()
        .append('path')
        .attr('d', arc)
        .attr('fill', (d, i) => d3.schemeCategory10[i]);
    }
  }, [data]);

  return <svg ref={d3Container}></svg>;
};

const AnalyticsSection = () => {
  const lineData = [
    { month: 'Jan', users: 100 },
    { month: 'Feb', users: 124 },
    { month: 'Mar', users: 50 },
    { month: 'Apr', users: 300 },
    { month: 'May', users: 500 },
    { month: 'Jun', users: 600 },
  ];

  const barData = [
    { category: 'A', value: 30 },
    { category: 'B', value: 80 },
    { category: 'C', value: 45 },
    { category: 'D', value: 60 },
  ];

  const pieData = [10, 20, 30, 40];

  return (
    <div className="bg-white p-4">
      <main className="flex-1">
        <h1 className="text-2xl font-bold mb-4">Analytics</h1>
        <div className="grid gap-4 mb-8 grid-cols-1 md:grid-cols-3">
          {/* Cards */}
          <div className="flex items-center p-3 bg-white border border-gray-200 rounded-lg shadow-xs">
            <div className="p-2 mr-3 text-orange-500 bg-orange-100 rounded-full">
              <User className="w-4 h-4" />
            </div>
            <div>
              <p className="mb-1 text-xs font-medium text-black">
                Total Users
              </p>
              <p className="text-sm font-semibold text-black">
                1,234
              </p>
            </div>
          </div>
          <div className="flex items-center p-3 bg-white border border-gray-200 rounded-lg shadow-xs">
            <div className="p-2 mr-3 text-green-500 bg-green-100 rounded-full">
              <Wallet className="w-4 h-4" />
            </div>
            <div>
              <p className="mb-1 text-xs font-medium text-black">
                Active Sessions
              </p>
              <p className="text-sm font-semibold text-black">
                123
              </p>
            </div>
          </div>
          <div className="flex items-center p-3 bg-white border border-gray-200 rounded-lg shadow-xs">
            <div className="p-2 mr-3 text-blue-500 bg-blue-100 rounded-full">
              <ShoppingBag className="w-4 h-4" />
            </div>
            <div>
              <p className="mb-1 text-xs font-medium text-black">
                Completed Sessions
              </p>
              <p className="text-sm font-semibold text-black">
                567
              </p>
            </div>
          </div>
        </div>
        <p className="text-sm my-4">Total Users in last 6 months</p>
        <LineGraph data={lineData} />
        <p className="text-sm my-4">Popular Categories and Users Gender</p>
        <div className="flex space-x-4 p-4">
          <div className="flex-1 items-center">
            <BarGraph data={barData} />
          </div>
          <div className="flex-1 items-center">
            <PieChart data={pieData} />
          </div>
        </div>
        
        {/* Latest Activity Table */}
        <p className="text-sm my-4">Latest Activity</p>

        <div className="latest-activity-table mt-8">
          <div className="w-full overflow-hidden rounded-md shadow-xs">
            <div className="w-full overflow-x-auto">
              <table className="w-full whitespace-no-wrap border border-gray-200">
                <thead>
                  <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b bg-gray-50">
                    <th className="px-4 py-3">Client</th>
                    <th className="px-4 py-3">Amount</th>
                    <th className="px-4 py-3">Status</th>
                    <th className="px-4 py-3">Date</th>
                    <th className="px-4 py-3">Actions</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y">
                  {/* Example row */}
                  <tr className="text-gray-700">
                    <td className="px-4 py-3">
                      <div className="flex items-center text-sm">
                        <div className="relative hidden w-8 h-8 mr-3 rounded-full md:block">
                          <img
                            className="object-cover w-full h-full rounded-full"
                            src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=200&facepad=3&fit=facearea&s=707b9c33066bf8808c934c8ab394dff6"
                            alt=""
                            loading="lazy"
                          />
                          <div className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true"></div>
                        </div>
                        <div>
                          <p className="font-semibold">Jolina Angelie</p>
                          <p className="text-xs text-gray-600">Unemployed</p>
                        </div>
                      </div>
                    </td>
                    <td className="px-4 py-3 text-sm">$369.95</td>
                    <td className="px-4 py-3 text-xs">
                      <span className="px-2 py-1 font-semibold leading-tight text-orange-700 bg-orange-100 rounded-full">
                        Pending
                      </span>
                    </td>
                    <td className="px-4 py-3 text-sm">6/10/2020</td>
                    <td className="px-4 py-3">
                      <div className="flex items-center space-x-4 text-sm">
                        <button
                          className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-purple-600 rounded-lg focus:outline-none focus:shadow-outline-gray"
                          aria-label="Edit"
                        >
                          <svg
                            className="w-5 h-5"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                          </svg>
                        </button>
                        <button
                          className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-purple-600 rounded-lg focus:outline-none focus:shadow-outline-gray"
                          aria-label="Delete"
                        >
                          <svg
                            className="w-5 h-5"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fillRule="evenodd"
                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                  {/* Add more rows as needed */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
       
      </main>
    </div>
  );
};

export default AnalyticsSection;
