import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAngleDown,
    faAngleUp,
    faBold,
    faItalic,
    faSquare,
    faUnderline,
    faEyeDropper
} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {SketchPicker} from "react-color";
import { convertHexToRGB } from "../../../utils/helpers";

const TextFormatAndFillControl = () => {

    const {activeSlide} = useSelector(state => state.slideList);
    const {activeObject} = useSelector(state => state.fabricCanvas);
    const [fill, setFill] = useState("rgb(0,0,0)");
    const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
    const colorPickerRef = useRef(null);

    useEffect(() => {
        if(activeObject) {
            const {fill} = activeObject;
            setFill(fill);
        }
    }, [activeObject])

    const toggleColorPicker = () => {
        setIsColorPickerOpen((prevIsColorPickerOpen) => !prevIsColorPickerOpen);
    };

    const handleClickOutside = (event) => {
        if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
            setIsColorPickerOpen(false);
        }
    };

    useEffect(() => {
        // Add event listener on mount
        document.addEventListener('mousedown', handleClickOutside);

        // Remove event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []); // Empty dependency array means this effect runs only once, on mount

    // Handler for text color selection
    const handleTextColorChange = (color) => {
        const {canvas} = activeSlide;
        let newValue;

        // If the color is selected form eye drop api it will have sRGBHex value else rgb
        if (color.sRGBHex) {
            newValue = convertHexToRGB(color.sRGBHex);
        } else {
            newValue = color.rgb;
        }

        setFill(`rgba(${newValue.r}, ${newValue.g}, ${newValue.b}, ${newValue.a})`);
        activeObject.set({
            fill: `rgba(${newValue.r}, ${newValue.g}, ${newValue.b}, ${newValue.a})`,
        });
        canvas.renderAll();
    }

    // Handler for text formatting selection
    const handleTextFormatting = (format) => {
        try {
            const {canvas} = activeSlide;
            const start = activeObject.selectionStart;
            const end = activeObject.selectionEnd;
            const currentSelectionStyles = activeObject.getSelectionStyles(start, end, true);
    
            switch (format) {
                case 'bold':
                    if (start - end === 0) {
                        activeObject.set({
                            fontWeight: activeObject.fontWeight === 'bold' ? '' : format,
                        });
                        activeObject.cleanStyle('fontWeight');
                    } else {
                        if (currentSelectionStyles) {
                            const modifiedStyles = {
                                ...currentSelectionStyles,
                                fontWeight: currentSelectionStyles[0].fontWeight === 'bold' ? '' : format
                            };
                            activeObject.setSelectionStyles(modifiedStyles, start, end);
                        } else {
                            activeObject.cleanStyle('fontWeight');
                        }
                    }
                    break;
                case 'italic':
                    if (start - end === 0) {
                        activeObject.set({
                            fontStyle: activeObject.fontStyle === 'italic' ? '' : format,
                        });
                        activeObject.cleanStyle('fontStyle');
                    } else {
                        if (currentSelectionStyles) {
                            const modifiedStyles = {
                                ...currentSelectionStyles,
                                fontStyle: currentSelectionStyles[0].fontStyle === 'italic' ? '' : format
                            };
                            activeObject.setSelectionStyles(modifiedStyles, start, end);
                        } else {
                            activeObject.cleanStyle('fontStyle');
                        }
                    }
                    break;
                case 'underline':
                    if (start - end === 0) {
                        activeObject.set({
                            underline: !activeObject.underline,
                        });
                        activeObject.cleanStyle('underline');
                    } else {
                        if (currentSelectionStyles) {
                            const modifiedStyles = {
                                ...currentSelectionStyles,
                                underline: !currentSelectionStyles[0].underline
                            };
                            activeObject.setSelectionStyles(modifiedStyles, start, end);
                        } else {
                            activeObject.cleanStyle('underline');
                        }
                    }
                    break;
                default:
                    break;
            }
    
            canvas.renderAll();
            
        } catch (error) {
            console.log('error>>>', error);
            
        }
    }

    const handleEyeDropperClick = () => {
        // eslint-disable-next-line no-undef
        const eyeDropper = new EyeDropper();
        eyeDropper.open().then((color) => {
            handleTextColorChange(color);
        });
    };

    return (
        <div className="mb-4 flex items-start w-full flex-col gap-2 space-x-4">
            <div className="flex items-center justify-center">
                {/* Text Bold Button */}
                <button
                    className="flex size-10 items-center justify-center rounded-l-md border bg-white text-xxs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200"
                    onClick={() => handleTextFormatting('bold')}
                >
                    <FontAwesomeIcon icon={faBold} className="size-3 text-gray-600"/>
                </button>

                {/* Text Italic Button */}
                <button
                    className="flex size-10 items-center justify-center border bg-white text-xxs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200"
                    onClick={() => handleTextFormatting('italic')}
                >
                    <FontAwesomeIcon icon={faItalic} className="size-3 text-gray-600"/>
                </button>

                {/* Text Underline Button */}
                <button
                    className="flex size-10 items-center justify-center rounded-r-md border bg-white text-xxs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200"
                    onClick={() => handleTextFormatting('underline')}
                >
                    <FontAwesomeIcon icon={faUnderline} className="size-3 text-gray-600"/>
                </button>
            </div>
                <div ref={colorPickerRef} className="relative flex items-center !ml-0">
                    <button
                        className={`flex flex-grow h-10 cursor-pointer items-center justify-center space-x-4 rounded-md rounded-r-none border px-3 text-xxs ${isColorPickerOpen ? 'bg-gray-100' : 'bg-white'}`}
                        onClick={toggleColorPicker}
                    >
                        <FontAwesomeIcon icon={faSquare} color={fill} className="size-3 mr-2"/>
                        {isColorPickerOpen && <FontAwesomeIcon icon={faAngleUp} className="size-3 !ml-1 text-gray-600"/>}
                        {!isColorPickerOpen && <FontAwesomeIcon icon={faAngleDown} className="size-3 !ml-1 text-gray-600"/>}
                    </button>
                    {isColorPickerOpen && (
                        <div className="absolute top-full right-0 mt-2 z-50">
                            <SketchPicker color={fill} onChange={handleTextColorChange}/>
                        </div>
                    )}
                    <div className="h-10 flex items-center px-3 border-y border-r rounded-r-md" onClick={handleEyeDropperClick}>
                        <FontAwesomeIcon icon={faEyeDropper} className="size-3" />
                    </div>
                </div>
        </div>
    )
}
export default TextFormatAndFillControl;