import React, { useEffect, useState } from 'react';
import AWS from 'aws-sdk';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import { useAuth, useCheckSubscription } from "../../context/AuthContext";

const MyFiles = ({ userId }) => {
  const { user } = useAuth();
  const checkSubscription = useCheckSubscription();
  const [files, setFiles] = useState([]);
  const [storageUsed, setStorageUsed] = useState(0); // Default value, will be updated
  const bucketName = 'slidesdeck';
  const [totalStorage, setTotalStorage] = useState(0); // Initialize as a number

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      const { data } = await checkSubscription(user);
      if (data && data.length > 0) {
        const storageGB = data[0]?.product?.storageGB || 'NA'; // Use data instead of subscriptionData
        setTotalStorage(storageGB * 1024 * 1024 * 1024); // Convert GB to bytes
      }
    };

    fetchSubscriptionData();
  }, [checkSubscription, user]);

  useEffect(() => {
    if (!user || !user.user_id) return; // Ensure user and user_id are available

    // Configure AWS SDK
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_SECRET_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const s3 = new AWS.S3();

    // Fetch files from S3
    const fetchFiles = async () => {
      try {
        const params = {
          Bucket: bucketName,
          Prefix: `${user.user_id}`, // Assuming files are stored under a prefix for each user
        };
        const data = await s3.listObjectsV2(params).promise();
       
        setFiles(data.Contents);

        // Calculate total storage used
        const totalSize = data.Contents.reduce((acc, file) => acc + file.Size, 0);
        setStorageUsed(totalSize);
      } catch (error) {
        console.error('Error fetching files from S3:', error); // Log the error
        if (error.code === 'NetworkingError') {
          console.error('Networking error details:', error); // Log networking error details
        } else if (error.code === 'AccessDenied') {
          console.error('Access denied error details:', error); // Log access denied error details
        } else {
          console.error('Other error details:', error); // Log other error details
        }
      }
    };

    fetchFiles();
  }, [user, bucketName]); // Include user in the dependency array

  const storagePercentage = (storageUsed / totalStorage) * 100; // Use totalStorage instead of setTotalStorage
  const storageUsedMB = (storageUsed / (1024 * 1024)).toFixed(2); // Convert bytes to MB

  return (
    <div className="flex h-screen bg-custom-skyBlue">
      <Sidebar />

      <div className="flex-1 flex flex-col">
        <TopBar />
        <main className="flex-1 bg-custom-skyBlue p-4 overflow-auto">
          <div className="bg-white p-6 rounded shadow">
            <h3 className="text-lg font-semibold mb-2">My Files</h3>
            <div className="w-full bg-gray-200 rounded-full h-4 mb-4 relative">
              <div
                className="bg-blue-600 h-4 rounded-full flex items-center text-black text-xs absolute left-0"
                style={{ width: `${storagePercentage}%` }}
              >
                <span className="px-2 whitespace-nowrap">
                  {storagePercentage.toFixed(2)}% ({storageUsedMB} MB)
                </span>
              </div>
            </div>
            <div className="flex justify-between text-sm text-gray-700 mt-2">
              <span>Total Storage: {(totalStorage / (1024 * 1024 * 1024)).toFixed(2)} GB</span>
              <span>Available Storage: {((totalStorage - storageUsed) / (1024 * 1024 * 1024)).toFixed(2)} GB</span>
            </div>
            <div className="relative overflow-x-auto">
              <table className="w-full text-sm border border-gray-300 text-left text-white dark:text-white">
                <thead className="text-xs text-white uppercase bg-custom-blue">
                  <tr>
                    <th scope="col" className="px-6 py-3">Name</th>
                    <th scope="col" className="px-6 py-3">Size (MB)</th>
                    <th scope="col" className="px-6 py-3">Type</th>
                  </tr>
                </thead>
                <tbody>
                  {files.map((file, index) => (
                    <tr
                      key={file.Key}
                      className={`bg-white border-b text-black ${index % 2 === 0 ? 'bg-light-blue-100' : ''}`}
                    >
                      <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap overflow-hidden overflow-ellipsis dark:text-black max-w-xs">
                        <a
                          href={`https://${bucketName}.s3.amazonaws.com/${file.Key}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-600 dark:text-blue-500 hover:underline"
                        >
                          {file.Key.split('/').pop()} {/* Extract only the file name */}
                        </a>
                      </td>
                      <td className="px-6 py-4">{(file.Size / (1024 * 1024)).toFixed(2)} MB</td>
                      <td className="px-6 py-4">{file.Key.split('.').pop()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default MyFiles;