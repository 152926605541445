// src/components/Sidebar.jsx
import React from "react";
import { useNavigate } from 'react-router-dom';
import { Home, Folder, UserLove, CartPlus, Plus, Learning, LogOut } from "iconoir-react"; // Import LogOut icon
import { useAuth } from "../../context/AuthContext";

const Sidebar = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <aside className="w-20 bg-custom-topBlue text-white text-xs flex flex-col items-center justify-between py-4">
      <div className="flex flex-col items-center">
        <div
          className="p-2 flex flex-col items-center cursor-pointer"
          onClick={() => navigate('/add-new-project')}
        >
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-gradient-to-r from-red-500 to-blue-500">
            <Plus className="w-6 h-6 text-white" />
          </div>
          <span>Add New</span>
        </div>
      </div>
      <nav className="flex-1 flex flex-col justify-center">
        <ul className="space-y-4">
          <li
            className="p-2 rounded-md m-2 hover:bg-custom-skyBlue hover:text-custom-dark flex flex-col items-center cursor-pointer"
            onClick={() => navigate('/dashboard')}
          >
            <Home className="w-6 h-6 hover:text-custom-dark" />
            <span>Home</span>
          </li>
          <li className="p-2 rounded-md m-2 hover:bg-custom-skyBlue hover:text-custom-dark flex flex-col items-center cursor-pointer"
            onClick={() => navigate('/my-files')}
          >
            <Folder className="w-6 h-6 hover:text-custom-dark" />
            <span>My Files</span>
          </li>
          <li className="p-2 rounded-md m-2 hover:bg-custom-skyBlue hover:text-custom-dark flex flex-col items-center cursor-pointer"
            onClick={() => navigate('/your-lms')}
          >
            <Learning className="w-6 h-6 hover:text-custom-dark" />
            <span>Your LMS</span>
          </li>

          <li className="p-2 rounded-md m-2 hover:bg-custom-skyBlue hover:text-custom-dark flex flex-col items-center">
            <CartPlus className="w-6 h-6 hover:text-custom-dark" />
            <span>Marketplace</span>
          </li>
          {/* <li className="p-2 rounded-md m-2 hover:bg-custom-skyBlue hover:text-custom-dark flex flex-col items-center">
            <HelpCircle className="w-6 h-6 hover:text-custom-dark" />
            <span>Support</span>
          </li> */}
          <li className="p-2 rounded-md m-2 hover:bg-custom-skyBlue hover:text-custom-dark flex flex-col items-center"
            onClick={() => navigate('/profile')}
          >
            <UserLove className="w-6 h-6 hover:text-custom-dark" />
            <span>Profile</span>
          </li>
        </ul>
      </nav>
      <hr className="w-full border-t border-gray-300 my-2" /> {/* Separator line */}
      <div
        className="p-2 rounded-md m-2 hover:bg-custom-skyBlue hover:text-custom-dark flex flex-col items-center cursor-pointer"
        onClick={handleLogout}
      >
        <LogOut className="w-6 h-6 hover:text-custom-dark" />
        <span>Log Out</span>
      </div>
    </aside>
  );
};

export default Sidebar;