import React, { useEffect, useCallback, useState, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { api } from "../../api/api";
import { deleteProject, setProjects } from "../../store/reducers/projectListSlice";
import moment from "moment/moment";
import { useAlert } from "../../context/AlertContext";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../context/AuthContext";
import { Edit, Eye, Learning, Plus, MoreVert } from "iconoir-react";
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import { createSCORMFiles, setupSlideList, setupSlideCanvases } from "../../utils/helpers";


const ListOfProject = () => {
  const { user } = useAuth();
  const { projects } = useSelector((state) => state.projects, shallowEqual);
  const { slideList } = useSelector((state) => state.slideList);
  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(null);
  const [deployedProjects, setDeployedProjects] = useState([]);
  const canvasRef = useRef(null); // Add a ref for the canvas

  

  const fetchProjects = useCallback(async () => {
    if (!user?.user_id) return;

    try {
      const response = await api.get(`/api/projects?filters[user_id]=${user.user_id}`);
      if (response.data) {
        dispatch(setProjects(response.data));
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  }, [dispatch, user?.user_id]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const deployProject = async (projectId) => {
    try {
      // Ensure slideList is set up for this project
      const slideList = await setupSlideList(dispatch, projectId);
      console.log("slideListDeploy", slideList);
  

      await createSCORMFiles(slideList, true, user.user_id, projectId);
  
      // Update project status to deployed
      const response = await api.put(`/api/projects/${projectId}`, {
        data: {
          deployed: true,
          deployedAt: new Date().toISOString()
        }
      });
  
      if (response.status === 200) {
        alert('Project deployed successfully!');
        setDeployedProjects((prev) => [...prev, projectId]);
      }
    } catch (error) {
      console.error('Error deploying project:', error);
      alert('Deployment failed. Please try again.');
    }
  };
  

  const downloadScormPackage = async (projectId) => {
    try {
      // Ensure slideList is set up for this project
      await setupSlideList(dispatch, projectId);

      // Set up canvases for each slide
      setupSlideCanvases(slideList, dispatch, canvasRef);

      // Proceed with creating the SCORM files
      createSCORMFiles(slideList, false, user.user_id, projectId);
    } catch (error) {
      console.error("Error downloading SCORM package:", error);
      showAlert("error", "Failed to download SCORM package.");
    }
  };
  const navigateToEditor = (projectId) => {
    window.open(`/editor/${projectId}`, '_self');
    console.log("Navigating to editor:", projectId);
  };

  const deleteProjects = async (projectId) => {
    try {
      const deleteResponse = await api.delete(`/api/projects/${projectId}`);
      if (deleteResponse) {
        showAlert("success", "Project deleted successfully!");
        dispatch(deleteProject(projectId));
      }
    } catch (error) {
      showAlert(
        "error",
        error?.response?.data?.error?.message || "Error in deleting a project"
      );
    }
  };

  const handleViewHistory = (projectId) => {
    navigate(`/project-history/${projectId}`);
  };

  const toggleMenu = (projectId) => {
    setMenuOpen((prev) => (prev === projectId ? null : projectId));
  };

  return (
    <div className="flex h-screen bg-custom-skyBlue">
      <Sidebar />

      <div className="flex-1 flex flex-col">
        <TopBar />

        <main className="flex-1 bg-custom-skyBlue p-4 overflow-auto">
          <div className="bg-white p-6 rounded shadow">
            <img src="/dashboardBanner.png" alt="Dashboard Banner" className="w-full h-auto mb-6" />
            <div className="flex space-x-2 mb-6">
              <button className="bg-gray-300 text-black p-2 rounded text-sm">Free Templates</button>
              <button className="bg-gray-300 text-black p-2 rounded text-sm">Presentation</button>
              <button className="bg-gray-300 text-black p-2 rounded text-sm">Document</button>
              <button className="bg-gray-300 text-black p-2 rounded text-sm">Custom Size</button>
              <div className="flex-1"></div>
              <button
                className="bg-blue-500 text-white p-2 px-12 rounded flex items-center"
                onClick={() => navigate('/add-new-project')}
              >
                <Plus className="mr-2" />
                Create New Project
              </button>
            </div>
            <h3 className="text-lg font-semibold mb-2">Recent Projects</h3>
            {projects.length > 0 ? (
              <div className="grid grid-cols-4 gap-4">
                {projects.map((project) => (
                  <div key={project.project_id} className="bg-white p-4 rounded shadow">
                    <img
                      src={`https://backend.ailms.co${project.project_image?.url}`}
                      alt={project.name}
                      className="w-full h-32 object-cover rounded"
                    />
                    <div className="flex justify-between items-center mt-2">
                      <h4 className="font-semibold">{project.name}</h4>
                      <div className="relative">
                        <button onClick={() => toggleMenu(project.project_id)}>
                          <MoreVert className="w-5 h-5 font-bold" />
                        </button>
                        {menuOpen === project.project_id && (
                          <div className="absolute top-8 right-0 bg-white shadow-md rounded p-2">
                            <button
                              className="text-xxs text-gray-700 hover:text-blue-500"
                              onClick={() => handleViewHistory(project.project_id)}
                            >
                              Versions
                            </button>
                            <button
                              className="text-xxs text-gray-700 hover:text-red-500 mt-1"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                deleteProjects(project?.project_id);
                              }}
                            >
                              Delete
                            </button>
                            <button
                              className="text-xxs text-gray-700 hover:text-blue-500 mt-1"
                              onClick={() => downloadScormPackage(project.project_id)}
                            >
                              Download
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <p className="text-xxs text-gray-500">
                      {moment(project.updatedAt).format("MMM Do YYYY")}
                    </p>
                    <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 mt-2">
                      <button
                        className="bg-gray-200 p-2 rounded flex items-center justify-center hover:bg-blue-400"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          navigateToEditor(project?.project_id);
                        }}
                      >
                        <Edit className="w-4 h-4 mr-1" />
                        <span className="text-xs">Edit</span>
                      </button>
                      <button
                        className={`bg-gray-200 p-2 rounded flex items-center justify-center ${deployedProjects.includes(project.project_id) ? 'cursor-not-allowed' : 'hover:bg-green-400'}`}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          deployProject(project?.project_id);
                        }}
                        disabled={deployedProjects.includes(project.project_id)}
                        title={deployedProjects.includes(project.project_id) ? "Project already deployed" : ""}
                      >
                        <Learning className="w-4 h-4 mr-1" />
                        <span className="text-xs">LMS</span>
                      </button>
                      <button
                        className="bg-gray-200 p-2 rounded flex items-center justify-center hover:bg-blue-400"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          navigate(`/edit-project/${project?.project_id}`);
                        }}
                      >
                        <Eye className="w-4 h-4 mr-1" />
                        <span className="text-xs">View</span>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-center text-gray-500">No projects available.</p>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default ListOfProject;
